import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { FileDto } from 'src/app/models/FileDto';

import { MessageCenterService } from 'src/app/services/message-center.service';
import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';
import { base64ToFile } from 'src/utils/base64-to-file';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(
    private http: HttpClient,
    private messageCenterService: MessageCenterService
  ) {}

  downloadFormPdf(id: number): Observable<ArrayBuffer> {
    return this.http
      .get<ArrayBuffer>(`${environment.apiUrl}/forms/pdf/${id}/download`, {
        responseType: 'arraybuffer' as 'json'
      })
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  downloadPdf(id: number): Observable<ArrayBuffer> {
    return this.http
      .get<ArrayBuffer>(`${environment.apiUrl}/files/${id}/download/file`, {
        responseType: 'arraybuffer' as 'json'
      })
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  replaceFile(
    fileId: number,
    fileName: string,
    newFile: string
  ): Observable<ArrayBuffer> {
    const formData = new FormData();
    const file = base64ToFile(newFile, fileName);
    formData.append('files', file);

    return this.http
      .post<ArrayBuffer>(
        `${environment.apiUrl}/files/replace-file/${fileId}`,
        formData,
        {
          responseType: 'arraybuffer' as 'json'
        }
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  updateFileLock(formId: number): Observable<FileDto> {
    return this.http.patch<FileDto>(
      `${environment.apiUrl}/files/update-file-lock/${formId}`,
      {}
    );
  }

  updateFileUnlock(formId: number): Observable<FileDto> {
    return this.http.patch<FileDto>(
      `${environment.apiUrl}/files/update-file-unlock/${formId}`,
      {}
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error.error);

    const translation = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      translation.summary,
      translation.detail,
      'error'
    );

    return throwError(() => error);
  }
}
