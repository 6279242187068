import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { first } from 'rxjs';
import { AppointmentDto } from 'src/app/models/Appointment';
import { HiveForm } from 'src/app/models/HiveForm';
import { isFormCompleted } from 'src/utils/is-form-completed';
import { getBackgroundColor, getIcon } from 'src/utils/ticket-helper';

@Component({
  selector: 'app-appointment-my-day',
  templateUrl: './appointment-my-day.component.html',
  styleUrl: './appointment-my-day.component.scss'
})
export class AppointmentMyDayComponent implements OnInit {
  @Input({ required: true }) appointment!: AppointmentDto;

  @Output() handleSwitchView = new EventEmitter<AppointmentDto>();

  @Output() handleReload = new EventEmitter<void>();

  completedForms: HiveForm[] = [];

  totalForms: number = 0;

  actionItems: MenuItem[] | undefined;

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const translationsPath = 'myDayComponent.actions';
    this.translate
      .get([
        `${translationsPath}.open`,
        `${translationsPath}.showTicket`,
        `${translationsPath}.showCustomer`,
        `${translationsPath}.callCustomerLandline`,
        `${translationsPath}.callCustomerMobile`
      ])
      .pipe(first())
      .subscribe((translations) => {
        const actionItems = [
          {
            label: translations[`${translationsPath}.open`],
            icon: 'pi pi-external-link',
            command: () => this.switchView()
          }
        ];

        if (this.appointment.ticket?.id) {
          actionItems.push({
            label: translations[`${translationsPath}.showTicket`],
            icon: 'pi pi-ticket',
            command: () => {
              this.router.navigate(['/tickets/', this.appointment.ticket?.id]);
            }
          });
        }

        if (this.appointment.ticket?.customerId) {
          actionItems.push({
            label: translations[`${translationsPath}.showCustomer`],
            icon: 'pi pi-user',
            command: () => {
              this.router.navigate([
                '/customers/',
                this.appointment.ticket?.customerId
              ]);
            }
          });
        }

        if (
          this.appointment.ticket?.customerContactPerson?.phoneNumberLandline
        ) {
          actionItems.push({
            label: translations[`${translationsPath}.callCustomerLandline`],
            icon: 'pi pi-phone',
            command: () => {
              window.location.href = `tel:${this.appointment.ticket?.customerContactPerson?.phoneNumberLandline}`;
            }
          });
        }

        if (this.appointment.ticket?.customerContactPerson?.phoneNumberMobile) {
          actionItems.push({
            label: translations[`${translationsPath}.callCustomerMobile`],
            icon: 'pi pi-mobile',
            command: () => {
              window.location.href = `tel:${this.appointment.ticket?.customerContactPerson?.phoneNumberMobile}`;
            }
          });
        }

        this.actionItems = actionItems;
      });

    this.totalForms = this.appointment.forms?.length || 0;
    if (this.appointment.forms) {
      for (const form of this.appointment.forms) {
        if (isFormCompleted(form)) {
          this.completedForms.push(form);
        }
      }
    }
  }

  checkAllFormsCompleted(): string {
    if (this.completedForms.length === this.totalForms) {
      return 'completed';
    }

    return '';
  }

  formatTime(date: string): string {
    return moment(date).format('HH:mm');
  }

  handleAppointmentClick(event: MouseEvent): void {
    // Check if the clicked element is the user avatar
    const target = event.target as HTMLElement;

    // Do not open detail view when clicking on user avatar
    if (target.closest('.user-avatar')) {
      return;
    }

    this.switchView();
  }

  switchView() {
    this.handleSwitchView.emit(this.appointment);
  }

  onMenuButtonClick(event: MouseEvent, menu: Menu) {
    // Don't open detail view when clicking on menu button.
    event.stopPropagation();
    // Open menu
    menu.toggle(event);
  }

  get backgroundColor(): string {
    if (!this.appointment.ticket) {
      return '#333';
    }

    return getBackgroundColor(this.appointment.ticket);
  }

  get icon(): string {
    if (!this.appointment.ticket) {
      return 'pi-calendar';
    }

    return getIcon(this.appointment.ticket);
  }
}
