import { AbilityBuilder, PureAbility } from '@casl/ability';

import { User, UserRole } from 'src/app/models/User';
import { environment } from 'src/environments/environment';

export const AppSubject = {
  User: 'User',
  Currency: 'Currency',
  Country: 'Country',
  Customer: 'Customer',
  TableFilter: 'TableFilter',
  HeatingEngineer: 'HeatingEngineer',
  Address: 'Address',
  CustomerCorrespondence: 'CustomerCorrespondence',
  Device: 'Device',
  CustomerDevice: 'CustomerDevice',
  CustomerContactPerson: 'CustomerContactPerson',
  Ticket: 'Ticket',
  Appointment: 'Appointment',
  DeviceManufacturer: 'DeviceManufacturer',
  ControlUnit: 'ControlUnit',
  UserWidget: 'UserWidget',
  Form: 'Form',
  File: 'File'
} as const;

export type AppAction = 'create' | 'read' | 'update' | 'delete';

export type AppSubject = (typeof AppSubject)[keyof typeof AppSubject];

export type AppAbility = PureAbility<
  [AppAction | 'manage', AppSubject | 'all']
>;

export const abilityFactory = (user: User | null): AppAbility | null => {
  if (!user) {
    return null;
  }

  const { can, build } = new AbilityBuilder<AppAbility>(PureAbility);

  // Global permissions

  can('read', 'Currency');
  can('read', 'Country');

  can('manage', 'TableFilter');
  can('manage', 'UserWidget');

  switch (user.role) {
    case UserRole.TechnicalAdmin:
      can('manage', 'all');

      return build();

    case UserRole.Admin:
      can('manage', 'all');

      return build();

    case UserRole.Dispatcher:
      can('read', 'HeatingEngineer');
      can('create', 'HeatingEngineer');
      can('update', 'HeatingEngineer');

      can('read', 'Address');
      can('create', 'Address');
      can('update', 'Address');

      can('read', 'Customer');
      can('create', 'Customer');
      can('update', 'Customer');

      can('read', 'CustomerContactPerson');
      can('update', 'CustomerContactPerson');
      can('create', 'CustomerContactPerson');

      can('read', 'Device');
      can('create', 'Device');
      can('update', 'Device');

      can('read', 'DeviceManufacturer');
      can('update', 'DeviceManufacturer');
      can('create', 'DeviceManufacturer');

      can('read', 'ControlUnit');
      can('update', 'ControlUnit');
      can('create', 'ControlUnit');

      can('read', 'CustomerDevice');
      can('create', 'CustomerDevice');
      can('update', 'CustomerDevice');

      can('read', 'CustomerCorrespondence');
      can('update', 'CustomerCorrespondence');
      can('create', 'CustomerCorrespondence');

      can('read', 'Ticket');
      can('update', 'Ticket');
      can('create', 'Ticket');

      can('read', 'Appointment');
      can('create', 'Appointment');
      can('update', 'Appointment');

      can('read', 'Form');
      can('create', 'Form');
      can('update', 'Form');

      can('read', 'File');
      can('create', 'File');
      can('update', 'File');

      return build();

    case UserRole.CustomerService:
      can('read', 'HeatingEngineer');

      can('read', 'Address');
      can('read', 'Customer');
      can('read', 'CustomerContactPerson');
      can('read', 'CustomerDevice');
      can('read', 'CustomerCorrespondence');

      can('read', 'Device');
      can('read', 'DeviceManufacturer');
      can('read', 'ControlUnit');

      can('read', 'Ticket');
      can('update', 'Ticket');
      can('create', 'Ticket');

      can('read', 'Appointment');
      can('create', 'Appointment');
      can('update', 'Appointment');

      can('read', 'Form');
      can('create', 'Form');
      can('update', 'Form');

      can('read', 'File');
      can('create', 'File');

      return build();

    case UserRole.Spectator:
      can('read', 'HeatingEngineer');

      can('read', 'Device');
      can('read', 'DeviceManufacturer');
      can('read', 'ControlUnit');

      can('read', 'Customer');
      can('read', 'CustomerContactPerson');
      can('read', 'Address');
      can('read', 'CustomerCorrespondence');
      can('read', 'CustomerDevice');

      can('read', 'Appointment');
      can('read', 'Ticket');
      can('read', 'Form');

      can('read', 'File');

      return build();

    default:
      return null;
  }
};

export const cookieKeys = {
  accessToken: `${environment.env}-access-token`,
  refreshToken: `${environment.env}-refresh-token`
} as const;
