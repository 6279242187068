<div class="ticket-summary-container" [ngClass]="colorScheme">
  <div
    class="ticket-summary-header"
    [style]="{backgroundColor: backgroundColor, color: '#fff'}">
    <span [class]="'pi '+icon"></span
    ><span class="ml-2"
      >{{'ticketComponent.ticketChips.'+ticket.ticketCategoryType |
      translate}}</span
    >
  </div>
  <div class="ticket-summary-body">
    <div class="ticket-number text-sm">
      <span>{{ ticket.ticketNumber }}</span>
    </div>
    <div class="font-bold cut-text pb-1">{{ ticket.subject }}</div>
    <div class="ticket-description pt-2 pb-2">{{ticket.description}}</div>
    <div *ngIf="ticket.customer" class="customer-container">
      <div>
        <span class="text-primary"> {{ ticket.customer.name}} </span>
      </div>
      <div>
        <span> {{ ticket.customer.customerNumber}} </span>
      </div>
      <div *ngIf="ticket.customer.facilityAddress">
        <span>
          {{ formatAddress(ticket.customer.facilityAddress, 'street')}}
        </span>
      </div>
      <div
        *ngIf="ticket.customer.facilityAddress"
        class="flex justify-content-between">
        <div>
          <span>
            {{ formatAddress(ticket.customer.facilityAddress, 'postal')}}
          </span>
        </div>
        <div class="flex gap-1 justify-content-end">
          <span
            *ngIf="ticket.customer && ticket.customer.facilityAddress"
            class="pi pi-map text-primary"
            (click)="openGoogleMaps($event, true)"></span>
          <span
            *ngIf="ticket.customer && ticket.customer.facilityAddress"
            class="pi pi-copy text-primary"
            (click)="openGoogleMaps($event,false)"></span>
        </div>
      </div>
    </div>
    <div class="mt-4 mr-2">
      <div class="flex gap-1 justify-content-end">
        <div
          (click)="appointmentPanel.toggle($event)"
          (click)="preventClick($event)"
          class="p-d-flex p-ai-center"
          *ngIf="ticket.appointments && ticket.appointments.length > 0">
          <i
            class="pi pi-calendar p-overlay-badge text-primary"
            style="font-size: 1.5rem">
            <span class="p-badge">{{ticket.appointments.length}}</span>
          </i>
        </div>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #appointmentPanel [showCloseIcon]="true">
  <app-ticket-appointment [ticket]="ticket"></app-ticket-appointment>
</p-overlayPanel>
