import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { first } from 'rxjs';
import {
  getEndDefault,
  getStartDefault
} from 'src/app/components/admin/my-day-overview/day-card/appointment-my-day/change-appointment-status/utils/get-default-appointment-date';

import { AppointmentDto } from 'src/app/models/Appointment';
import { AppointmentService } from 'src/app/services/api/appointment.service';

@Component({
  selector: 'app-change-appointment-status',
  templateUrl: './change-appointment-status.component.html',
  styleUrl: './change-appointment-status.component.scss'
})
export class ChangeAppointmentStatusComponent implements OnInit, OnChanges {
  @Input({ required: true }) appointment!: AppointmentDto;

  @Output() updateView = new EventEmitter<void>();

  isDialogVisible = false;

  selectedStartDate = new Date();

  selectedEndDate = new Date();

  constructor(private readonly appointmentService: AppointmentService) {}

  ngOnInit(): void {
    this.selectedStartDate = getStartDefault(this.appointment);
    this.selectedEndDate = getEndDefault(this.appointment);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appointment']) {
      this.selectedStartDate = getStartDefault(this.appointment);
      this.selectedEndDate = getEndDefault(this.appointment);
    }
  }

  onOpenDialog() {
    if (this.appointment.isCompleted) {
      this.updateAppointment(
        this.appointment.previousStart,
        this.appointment.previousEnd
      );

      return;
    }

    if (this.appointment.allDay) {
      this.updateAppointment(this.appointment.start, this.appointment.end);

      return;
    }

    this.isDialogVisible = true;
  }

  onDialogCancel() {
    this.isDialogVisible = false;
  }

  onDialogConfirm() {
    this.updateAppointment(
      this.selectedStartDate.toISOString(),
      this.selectedEndDate.toISOString()
    );
  }

  private updateAppointment(startDate: string, endDate: string) {
    this.appointmentService
      .markAsCompleted(this.appointment.id, {
        isCompleted: !this.appointment.isCompleted,
        endDate,
        startDate
      })
      .pipe(first())
      .subscribe(() => {
        this.isDialogVisible = false;
        this.updateView.emit();
      });
  }
}
