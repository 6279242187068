import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { first } from 'rxjs';
import { DeviceManufacturer } from 'src/app/models/DeviceManufacturer';
import { Ticket } from 'src/app/models/Ticket';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';
import { CustomerDeviceService } from 'src/app/services/api/customer/customer-device.service';
import { DeviceManufacturerService } from 'src/app/services/api/device-manufacturer.service';

@Component({
  selector: 'app-ticket-devices',
  templateUrl: './ticket-devices.component.html',
  styleUrls: ['./ticket-devices.component.scss']
})
export class TicketDevicesComponent implements OnInit, OnChanges {
  @Input() formGroupName!: string;

  @Input() ticket!: Ticket;

  @Output() ticketChange = new EventEmitter<Ticket>();

  form!: FormGroup;

  customerDevices: CustomerDevice[] = [];

  devices: CustomerDevice[] = [];

  customerDevicesShadow: CustomerDevice[] = [];

  customerDeviceModalVisible = false;

  reset = true;

  deviceManufacturers: DeviceManufacturer[] = [];

  constructor(
    private rootFormGroup: FormGroupDirective,
    private readonly customerDeviceService: CustomerDeviceService,
    private readonly deviceManufacturerService: DeviceManufacturerService
  ) {}

  ngOnInit(): void {
    this.initializeCustomerDevices();
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.loadCustomerDevices();
    this.deviceManufacturerService
      .findAll()
      .pipe(first())
      .subscribe((deviceManufacturers) => {
        this.deviceManufacturers = deviceManufacturers;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ticket']) {
      this.initializeCustomerDevices();
      this.loadCustomerDevices();
    }
  }

  /**
   * Initializes the customer devices from the ticket.
   */
  private initializeCustomerDevices(): void {
    if (this.ticket.customerDevices) {
      this.customerDevices = this.ticket.customerDevices;
      this.customerDevicesShadow = [...this.customerDevices];
    }
  }

  /**
   * Loads the customer devices from the service.
   */
  private loadCustomerDevices(): void {
    if (this.ticket.customer) {
      this.customerDeviceService
        .findAll(this.ticket.customer.id)
        .pipe(first())
        .subscribe((devices) => {
          if (this.ticket.id) {
            this.devices = devices;
          } else {
            this.devices = devices.filter((device) => device.isActive);
          }
        });
    }
  }

  customerDeviceRemoved(device: CustomerDevice): void {
    const deviceIdToRemove = device.id;

    this.customerDevices = this.customerDevices.filter(
      (dev) => dev.id !== deviceIdToRemove
    );

    this.devicesChanged(this.customerDevices);
  }

  selectCustomerDevice(): void {
    this.customerDeviceModalVisible = true;
  }

  public hideCustomerDeviceModal(): void {
    this.customerDeviceModalVisible = false;
    if (this.reset) {
      this.customerDevices = this.customerDevicesShadow;
      this.devicesChanged(this.customerDevices);
    } else {
      this.reset = false;
    }
  }

  saveDevices(): void {
    this.devicesChanged(this.customerDevices);
    this.reset = false;
    this.customerDeviceModalVisible = false;
  }

  devicesChanged(customerDevices: CustomerDevice[]): void {
    this.form.markAsDirty();
    this.customerDevices = customerDevices;
    if (
      this.ticket.customerDevices &&
      !this.haveSameIds(this.ticket.customerDevices, this.customerDevices)
    ) {
      this.ticket.customerDevices = this.customerDevices;
      this.ticketChange.emit(this.ticket);
    }
  }

  haveSameIds(
    devicesFromTicket: CustomerDevice[],
    devicesSelected: CustomerDevice[]
  ): boolean {
    const ids1 = devicesFromTicket.map((device) => device.id);
    const ids2 = devicesSelected.map((device) => device.id);

    return (
      ids1.every((id) => ids2.includes(id)) &&
      ids2.every((id) => ids1.includes(id))
    );
  }
}
