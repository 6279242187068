import { HiveForm } from './HiveForm';
import { Ticket } from './Ticket';
import { User } from './User';

export class Appointment {
  id?: number;

  originalId?: number;

  title!: string;

  notes?: string;

  start!: Date;

  end!: Date;

  previousStart?: Date;

  previousEnd?: Date;

  backgroundColor!: string;

  borderColor!: string;

  textColor!: string;

  description!: string;

  ticketId?: number;

  ticket?: Ticket;

  technicianId?: number;

  technician?: User;

  otherTechnicians?: User[];

  otherTechniciansIds?: number[];

  allDay!: boolean;

  reserved?: boolean;

  contactName: string | null = null;

  contactPhone: string | null = null;

  forms?: HiveForm[] = [];

  deletedAt?: Date;
}
export type AppointmentDto = {
  id: number;

  title: string;

  start: string;

  end: string;

  previousStart: string;

  previousEnd: string;

  backgroundColor: string;

  borderColor: string;

  textColor: string;

  description: string;

  ticketId: number | null;

  ticket?: Ticket;

  editorId: number | null;

  editor?: User;

  forms?: HiveForm[];

  technician?: User;

  technicianId: number | null;

  otherTechnicians?: User[];

  otherTechniciansIds?: number[];

  notes?: string;

  isCompleted: boolean;

  allDay: boolean;

  contactName: string | null;

  contactPhone: string | null;
};
export type MyDayView = {
  currentRelevant: {
    date: string;
    appointments: AppointmentDto[];
  };
  nextRelevant: {
    date: string;
    appointments: AppointmentDto[];
  };
  beyondNextRelevant: {
    date: string;
    appointments: AppointmentDto[];
  };
};

export type MarkAsCompleted = {
  isCompleted: boolean;

  startDate: string | null;
  endDate: string | null;
};
