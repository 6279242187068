/* eslint-disable max-lines */
/* eslint-disable id-length */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  RendererFactory2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subscription, first, forkJoin, map } from 'rxjs';
import { FileUploadComponent } from 'src/app/components/misc/file-upload/file-upload.component';
import { FileDto } from 'src/app/models/FileDto';
import { User } from 'src/app/models/User';
import { GenericService } from 'src/app/services/api/generic.service';
import { PdfService } from 'src/app/services/api/pdf.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { FileTypes } from 'src/app/types/misc/FileTypes';
import { Severity } from 'src/app/types/misc/Severity';
import { bytesToSize } from 'src/app/utils/bytes/byte-size.formatter';
import { FileDownloader } from 'src/app/utils/other/download-helper';
import { hasPermissionToUnlockFiles } from 'src/app/utils/role/role.utils';
import { AppAction } from 'src/config/authorization.config';
import { blobToBase64 } from 'src/utils/blob-to-base64';

type ViewType = 'pdf' | 'office';
type SortOption = 'asc' | 'desc' | 'none';

type PreviewEvent = {
  file: FileDto;
  type: 'pdf' | 'image' | 'msg';
};

type FileSelectionMode = 'multiple' | 'none';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnDestroy, OnInit, OnChanges {
  @Input() filesList!: FileDto[];

  @Input() context!: string;

  @Input() uploadIncluded = true;

  @Input() showFilterBar = false;

  @Input() showUploadComponent = false;

  @Input() showDragAndDropArea = false;

  @Input() delegatePreview = false;

  @Input() showUploadBtn = true;

  @Input() showRemoveBtn = true;

  @Input() emitRemoveOnly = false;

  @Input() selectedFiles: FileDto[] = [];

  @Input() selectionMode: FileSelectionMode = 'none';

  @Input() showCustomButton = false;

  @Input() maxHeight: number | undefined = undefined;

  @Input() customButtonIcon = '';

  @Output() customUploadButtonClicked = new EventEmitter<void>();

  @Output() fileRemoved = new EventEmitter<FileDto>();

  @Output() choose = new EventEmitter<File[]>();

  @Output() upload = new EventEmitter<File[]>();

  @Output() preview = new EventEmitter<PreviewEvent>();

  @Output() selectedFilesChange = new EventEmitter<FileDto[]>();

  @ViewChild('fileUploadComponent') fileUploadComponent?: FileUploadComponent;

  @ViewChild('op') overlayPanel?: OverlayPanel | undefined;

  filesListShadow!: FileDto[];

  fileToView: FileDto | null = null;

  fileURL: string | null = null;

  FileTypes = FileTypes;

  isLoading = true;

  msgFile: FileDto | null = null;

  subscriptions = new Subscription();

  viewType: ViewType = 'pdf';

  visible = false;

  searchTerm = '';

  fileNameFilter = '';

  createdAtFilter: Date[] = [];

  createdByFilter!: User;

  createdByOptions: User[] = [];

  fileNameSort: SortOption = 'none';

  createdAtSort: SortOption = 'none';

  createdBySort: SortOption = 'none';

  /**
   * Whether the file modal is visible
   */
  isFileModalVisible = false;

  /**
   * Cache for storing file URLs to prevent multiple downloads
   */
  fileCache: Map<number, string> = new Map();

  private fileDownloader: FileDownloader;

  public blob: Blob | undefined;

  editFile: FileDto | null = null;

  currentUser: User | null = null;

  /**
   * Constructor for the FilesComponent.
   * @param {GenericService<'Files'>} genericService - The generic service for file operations.
   * @param {RendererFactory2} rendererFactory - The renderer factory.
   */
  constructor(
    private genericService: GenericService<'Files'>,
    private rendererFactory: RendererFactory2,
    private pdfService: PdfService,
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private readonly pdfViewerService: NgxExtendedPdfViewerService,
    private readonly authService: AuthService
  ) {
    this.fileDownloader = new FileDownloader(this.rendererFactory);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['filesList'] &&
      changes['filesList'].previousValue !== undefined
    ) {
      this.remount();
    }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getLoggedInUser();
    this.loadFiles();
  }

  ngOnDestroy(): void {
    this.unloadFiles();
    this.subscriptions.unsubscribe();
  }

  public remount() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();

    this.unloadFiles();

    if (this.fileUploadComponent) {
      this.fileUploadComponent.reset();
    }

    this.isLoading = true;
    this.overlayPanel?.hide();

    this.loadFiles();
  }

  loadFiles(): void {
    this.filesListShadow = this.filesList;

    // Filter files that have an ID
    const filesWithId = this.filesList.filter((file) => file.id);

    if (filesWithId.length > 0) {
      const requests = filesWithId.map((file) =>
        this.genericService.getFile(file.id, this.context, file.updatedAt)
      );

      this.createdByOptions = Array.from(
        new Set(
          filesWithId.map((file) => JSON.stringify(file.createdBy as User))
        )
      ).map((user) => JSON.parse(user));

      this.subscriptions.add(
        forkJoin(requests).subscribe({
          next: (responses) => {
            responses.forEach((data, index) => {
              const fileIndex = this.filesList.findIndex(
                (file) => file.id === filesWithId[index].id
              );
              if (fileIndex !== -1) {
                this.filesList[fileIndex].buffer = data;
                this.filesList[fileIndex].previewUrl =
                  this.fileDownloader.createBlobUrl(
                    data,
                    this.filesList[fileIndex].mimetype
                  );
              }
            });
            this.isLoading = false;
          },
          error: (error) => {
            console.error('Error:', error);
            this.isLoading = false;
          }
        })
      );
    } else {
      this.isLoading = false;
    }
  }

  _isFileSelected(event: FileDto): boolean {
    return this.selectedFiles.includes(event);
  }

  _fileSelectionChanged(file: FileDto) {
    file.selected = !file.selected;
    if (file.selected) {
      this.selectedFiles.push(file);
    } else {
      this.selectedFiles = this.selectedFiles.filter((f) => f !== file);
    }
    this.selectedFilesChange.emit(this.selectedFiles);
  }

  unloadFiles(): void {
    if (this.filesList && this.filesList.length > 0) {
      this.filesList.forEach((file) => {
        if (file.previewUrl) {
          this.fileDownloader.revokeBlobUrl(file.previewUrl);
        }
      });
    }
  }

  /**
   * Removes the specified file from the uploader.
   * @param {FileDto} file - The file to remove.
   * @returns {void}
   */
  removeFile(file: FileDto): void {
    // Remove the specified file from the uploader
    const index = this.filesList.indexOf(file);
    if (index !== -1) {
      this.filesList[index].deletedAt = new Date();
      this.fileRemoved.emit(this.filesList[index]);
    }
  }

  displaySize(file: FileDto): string {
    return bytesToSize(file.size);
  }

  /**
   * Downloads the specified file.
   * @param {FileDto} file - The file to download.
   * @returns {void}
   */
  download(file: FileDto): void {
    if (file.buffer) {
      this.fileDownloader.downloadFile(
        file.buffer,
        file.originalname,
        file.mimetype
      );
    }
  }

  /**
   * Opens the file preview for the specified file.
   * @param {FileDto} file - The file to preview.
   * @param {ViewType} viewType - The type of view to use for the preview.
   * @returns {void}
   */
  openFilePreview(file: FileDto, viewType: ViewType): void {
    this.viewType = viewType;
    this.fileToView = file;
    this.visible = true;
  }

  /**
   * Shows the preview for the specified .msg file.
   * @param {FileDto} file - The file to show the preview for.
   * @returns {void}
   */
  showMsgPreview(file: FileDto): void {
    this.msgFile = file;
  }

  /**
   * Clears the date filter and resets the files list to its original state.
   * @returns {void}
   */
  clearDateFilter(): void {
    this.createdAtFilter = [];
    this.filesList = this.filesListShadow.slice(0, this.filesListShadow.length);
  }

  /**
   * Sorts the files list by the 'createdAt' property.
   * @param {SortOption} sortType - The type of sort ('asc', 'desc', 'none').
   * @returns {void}
   */
  sortFilesByDate(sortType: SortOption): void {
    this.createdAtSort = sortType;
    if (sortType === 'asc') {
      this.filesList = this.filesList.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    } else if (sortType === 'desc') {
      this.filesList = this.filesList.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else if (sortType === 'none') {
      this.filesList = this.filesListShadow.slice(
        0,
        this.filesListShadow.length
      );
    }
  }

  /**
   * Clears the filename filter and resets the files list to its original state.
   * @returns {void}
   */
  clearFilenameFilter(): void {
    this.fileNameFilter = '';
    this.filesList = this.filesListShadow.slice(0, this.filesListShadow.length);
  }

  /**
   * Sorts the files list by the 'filename' property.
   * @param {SortOption} sortType - The type of sort ('asc', 'desc', 'none').
   * @returns {void}
   */
  sortFilesByName(sortType: SortOption): void {
    this.fileNameSort = sortType;
    if (sortType === 'asc') {
      this.filesList = this.filesList.sort((a, b) =>
        a.filename.localeCompare(b.filename)
      );
    } else if (sortType === 'desc') {
      this.filesList = this.filesList.sort((a, b) =>
        b.filename.localeCompare(a.filename)
      );
    } else if (sortType === 'none') {
      this.filesList = this.filesListShadow.slice(
        0,
        this.filesListShadow.length
      );
    }
  }

  /**
   * Clears the 'createdBy' filter and resets the files list to its original state.
   * @returns {void}
   */
  clearCreatedByFilter(): void {
    this.createdByFilter = new User();
    this.filesList = this.filesListShadow.slice(0, this.filesListShadow.length);
  }

  /**
   * Sorts the files list by the 'firstname' property of the 'createdBy' object.
   * @param {SortOption} sortType - The type of sort ('asc', 'desc', 'none').
   * @returns {void}
   */
  sortFilesByUser(sortType: SortOption): void {
    this.createdBySort = sortType;
    if (sortType === 'asc') {
      this.filesList = this.filesList.sort((a, b) =>
        (a.createdBy?.firstname ?? '').localeCompare(
          b.createdBy?.firstname ?? ''
        )
      );
    } else if (sortType === 'desc') {
      this.filesList = this.filesList.sort((a, b) =>
        (b.createdBy?.firstname ?? '').localeCompare(
          a.createdBy?.firstname ?? ''
        )
      );
    } else if (sortType === 'none') {
      this.filesList = this.filesListShadow.slice(
        0,
        this.filesListShadow.length
      );
    }
  }

  /**
   * Handles the select event.
   * @param {File[]} event - The select event.
   * @returns {void}
   */
  onUpload(event: File[]): void {
    this.upload.emit(event);
  }

  /**
   * Handles the select event.
   * @param {File[]} event - The select event.
   * @returns {void}
   */
  onSelect(event: File[]): void {
    this.choose.emit(event);
  }

  hidePdfViewer(): void {
    if (this.fileToView) {
      this.fileToView = null;
    }

    this.isFileModalVisible = false;
  }

  /**
   * Exports the current PDF document by replacing an existing file with the updated version.
   * This involves fetching the document as a Blob, converting it to Base64,
   * and updating the corresponding file in the system.
   *
   * @returns {Promise<void>} A promise that resolves once the export process completes.
   */
  public async export(): Promise<void> {
    // Fetch the current document as a Blob from the PDF viewer service
    this.blob = await this.pdfViewerService.getCurrentDocumentAsBlob();
    this.isLoading = true;
    if (this.blob) {
      // Convert the Blob to Base64 format
      this.subscriptions.add(
        blobToBase64(this.blob).subscribe((base64) => {
          // Ensure all required IDs and the Blob are available
          if (this.editFile?.id && base64) {
            this.subscriptions.add(
              this.pdfService
                .replaceFile(
                  this.editFile.id, // ID of the file to be replaced
                  this.editFile.originalname,
                  base64 // Base64 encoded Blob
                )
                .subscribe((file) => {
                  if (file) {
                    this.isFileModalVisible = false;

                    this.messageCenterService.showToast(
                      this.translate.instant('general.form.editPdf.header'),
                      this.translate.instant('general.form.editPdf.message'),
                      'success'
                    );
                    if (this.editFile) {
                      this.resetLockedState(this.editFile.id);
                    }

                    this.remount();

                    this.isLoading = false;
                  }
                })
            );
          } else {
            console.warn('Missing required IDs or Blob for file replacement');
          }
        })
      );
    } else {
      console.warn('No Blob found. Cannot export the document.');
    }
  }

  /**
   * Cancels the edit operation and unlocks the form file.
   * This method clears the file cache, form, and file states.
   *
   * @returns {Promise<void>} A promise that resolves when the edit operation is canceled.
   */
  async cancelEdit(): Promise<void> {
    const cancel = await this.cancelFormEdit();

    if (this.editFile && cancel) {
      const fileId = this.editFile.id;
      if (fileId) {
        this.subscriptions.add(
          this.pdfService.updateFileUnlock(fileId).subscribe({
            next: (updatedFile: FileDto) => {
              if (updatedFile) {
                // Clear the file cache
                this.fileCache.forEach((url) => {
                  URL.revokeObjectURL(url);
                });
                this.fileCache.clear();

                this.resetLockedState(fileId);

                // Clear the form and file states
                this.editFile = null;
                this.fileToView = null;
                this.isFileModalVisible = false;
              }
            }
          })
        );
      }
    }
  }

  /**
   * Prompts the user to confirm the cancellation of editing the form pdf.
   *
   * @returns {Promise<boolean>} A promise that resolves to true if the user confirms the cancellation, false otherwise.
   */
  async cancelFormEdit(): Promise<boolean> {
    const cancel = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('formComponent.formLocked.cancelModal.header'),
        this.translate.instant('formComponent.formLocked.cancelModal.message'),
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });

    return cancel;
  }

  getFileName(): string {
    return this.editFile?.originalname ?? '';
  }

  /**
   * Opens the PDF viewer in editMode for the given form
   */
  editPdf(file: FileDto): void {
    if (!file.id) {
      return;
    }

    this.editFile = file;

    this.subscriptions.add(
      this.pdfService.updateFileLock(file.id).subscribe({
        next: (updatedFile: FileDto) => {
          if (updatedFile) {
            // Download the PDF and cache the URL
            if (file.id) {
              // Show the modal
              this.isFileModalVisible = true;
              this.subscriptions.add(
                this.fetchPdf(file.id).subscribe((url: string) => {
                  this.fileURL = url;
                })
              );
            }
          }
        }
      })
    );
  }

  /**
   * Unlocks the PDF file associated with the given file.
   * This method updates the file lock status and shows a toast message based on the result.
   *
   * @param {FileDto} file - The file to unlock.
   * @returns {void}
   */
  unlockPdf(file: FileDto): void {
    if (file.id) {
      this.isLoading = true;
      this.subscriptions.add(
        this.pdfService.updateFileUnlock(file.id).subscribe({
          next: (updatedFile: FileDto) => {
            let severity: Severity = 'error';
            if (updatedFile) {
              severity = 'success';
              // Show a success toast message
            }
            this.resetLockedState(file.id);
            this.showUnlockToast(severity);
            // Set the loading state to false
            this.isLoading = false;
          },
          error: () => {
            this.showUnlockToast('error');
            // Set the loading state to false
            this.isLoading = false;
          }
        })
      );
    }
  }

  /**
   * Shows a toast message indicating the result of the unlock operation.
   *
   * @param {Severity} severity - The severity level of the toast message.
   * @returns {void}
   */
  showUnlockToast(severity: Severity): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `formComponent.formLocked.unlockToast.${severity}.summary`
      ),
      this.translate.instant(
        `formComponent.formLocked.unlockToast.${severity}.details`
      ),
      severity
    );
  }

  private fetchPdf(fileId: number): Observable<string> {
    const cached = this.fileCache.get(fileId);

    if (cached) {
      return new Observable((observer) => {
        observer.next(cached);
        observer.complete();
      });
    }

    return this.pdfService.downloadPdf(fileId).pipe(
      first(),
      map((data: ArrayBuffer) => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        this.fileCache.set(fileId, url);

        return url;
      })
    );
  }

  /**
   * Checks if the form file is locked by the current user.
   *
   * @param {FileDto} file - The form to check.
   * @returns {boolean} True if the form file is locked by the current user, false otherwise.
   */
  fileIsLockedByCurrentUser(file: FileDto): boolean {
    if (file) {
      return (
        file.fileLockedAt !== null &&
        file.fileLockedById === this.currentUser?.id
      );
    }

    return false;
  }

  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'File');
  }

  /**
   * Checks if the form file is locked by another user.
   *
   * @param {FileDto} file - The form to check.
   * @returns {boolean} True if the form file is locked by another user, false otherwise.
   */
  fileIsLockedByOtherUser(file: FileDto): boolean {
    if (file) {
      return (
        file.fileLockedAt !== null &&
        file.fileLockedById !== this.currentUser?.id
      );
    }

    return false;
  }

  resetLockedState(fileId: number): void {
    this.filesList[
      this.filesList.findIndex((f) => f.id === fileId)
    ].fileLockedAt = null;

    this.filesList[
      this.filesList.findIndex((f) => f.id === fileId)
    ].fileLockedBy = null;

    this.filesList[
      this.filesList.findIndex((f) => f.id === fileId)
    ].fileLockedById = null;

    this.filesList[this.filesList.findIndex((f) => f.id === fileId)].updatedAt =
      new Date();
  }

  /**
   * Checks if the current user has permission to unlock the file.
   *
   * @returns {boolean} True if the user has permission to unlock the file, false otherwise.
   */
  hasPermissionToUnlock(): boolean {
    if (!this.currentUser) {
      return false;
    }

    return hasPermissionToUnlockFiles(this.currentUser);
  }
}

export { type PreviewEvent as FilePreviewEvent };
