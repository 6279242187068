import { AppointmentDto } from 'src/app/models/Appointment';

export const getStartDefault = (appointment: AppointmentDto | null) => {
  if (!appointment) {
    return new Date();
  }

  return new Date(appointment.start);
};

export const getEndDefault = (appointment: AppointmentDto | null) => {
  if (!appointment) {
    return new Date();
  }

  if (appointment.allDay) {
    return new Date(appointment.end);
  }

  const now = new Date();
  const start = new Date(appointment.start);
  const end = new Date(appointment.end);

  // if now is between start and end

  if (now > start && now < end) {
    return now;
  }

  return end;
};
