<div class="card">
  <p-table
    #tableElement
    dataKey="id"
    [rows]="tableConfig.rows"
    [filterDelay]="1"
    [scrollHeight]="tableConfig.scrollHeight"
    [value]="virtualCustomers"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="'table.pageReportTemplate' | translate"
    [globalFilterFields]="['virtual.customer', 'virtual.virtualSearch.heatingEngineerSearch', 'virtual.virtualSearch.deviceSearch', 'virtual.virtualSearch.facilityAddressSearch', 'virtual.contactPerson', 'virtual.ticketCount', 'virtual.deviceCount', 'virtual.isGasSeparatorInstalled', 'virtual.deviceSerialNumbers']"
    [rowsPerPageOptions]="tableConfig.rowsPerPageOptions"
    [loading]="isLoading"
    [scrollable]="true"
    [resizableColumns]="true"
    columnResizeMode="expand"
    stateStorage="local"
    stateKey="customer-table-memory">
    <ng-template pTemplate="caption">
      <div class="flex relative align-items-center justify-content-between">
        <div class="flex gap-4 align-items-center">
          <div (mousedown)="reloadCustomers()">
            <i role="button" class="pi pi-sync cursor-pointer"></i>
          </div>

          <div>
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                [value]="globalSearchValue"
                (input)="tableElement.filterGlobal($any($event.target).value, 'contains')"
                [placeholder]="'general.globalSearchPlaceholder' | translate" />
            </span>
          </div>
        </div>
        <div *ngIf="tableConfig.showTableHeader" class="title-container">
          <h4>{{ "customerComponent.table.title" | translate}}</h4>
        </div>
        <div *ngIf="showCreateAction">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-plus-circle"
            [label]="'customerComponent.newCustomer' | translate"
            class="flex-auto p-button-outlined"
            (click)="createCustomer()"></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th
          pResizableColumn
          pSortableColumn="virtual.customer"
          class="white-space-nowrap"
          alignFrozen="left"
          pFrozenColumn>
          {{ 'customerComponent.table.columns.customer.title' | translate }}
          <p-sortIcon field="virtual.customer" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.virtualSearch.heatingEngineerSearch"
          class="white-space-nowrap">
          {{ 'customerComponent.table.columns.heatingEngineers.title' |
          translate }}
          <p-sortIcon field="virtual.virtualSearch.heatingEngineerSearch" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.facilityAddress"
          class="white-space-nowrap">
          {{ 'customerComponent.table.columns.facilityAddressId.title' |
          translate }}
          <p-sortIcon field="virtual.facilityAddress" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.contactPerson"
          class="white-space-nowrap">
          {{ 'customerComponent.table.columns.contactPerson.title' | translate
          }}
          <p-sortIcon field="virtual.contactPerson" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.ticketCount"
          class="white-space-nowrap">
          {{ 'customerComponent.table.columns.tickets.title' | translate }}
          <p-sortIcon field="virtual.ticketCount" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.deviceCount"
          class="white-space-nowrap">
          {{ 'customerComponent.table.columns.devices.title' | translate }}
          <p-sortIcon field="virtual.deviceCount" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.virtualSearch.deviceSearch"
          class="white-space-nowrap">
          {{ 'customerComponent.table.columns.devices.title' | translate }}
          <p-sortIcon field="virtual.virtualSearch.deviceSearch" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.deviceSerialNumbers"
          class="white-space-nowrap">
          {{ 'customerComponent.table.columns.deviceSerialNumbers.title' |
          translate }}
          <p-sortIcon field="virtual.deviceSerialNumbers" />
        </th>
        <th
          pResizableColumn
          pSortableColumn="virtual.isGasSeparatorInstalled"
          class="white-space-nowrap">
          {{
          'customerDeviceComponent.table.columns.isGasSeparatorInstalled.title'
          | translate }}
          <p-sortIcon field="isGasSeparatorInstalled" />
        </th>
        <th alignFrozen="right" pFrozenColumn>
          {{ 'table.actionsTitle' | translate}}
        </th>
      </tr>

      <tr>
        <th alignFrozen="left" pFrozenColumn>
          <p-columnFilter
            field="virtual.customer"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.virtualSearch.heatingEngineerSearch"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.virtualSearch.facilityAddressSearch"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.contactPerson"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th></th>
        <th></th>
        <th>
          <p-columnFilter
            field="virtual.virtualSearch.deviceSearch"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.deviceSerialNumbers"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter
            field="virtual.isGasSeparatorInstalled"
            matchMode="contains"
            [showMenu]="false">
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback">
              <input
                type="text"
                pInputText
                [ngModel]="value"
                (ngModelChange)="filter($event)"
                class="p-inputtext" />
            </ng-template>
          </p-columnFilter>
        </th>
        <th alignFrozen="right" pFrozenColumn>
          <div class="flex gap-2">
            <button
              pButton
              pRipple
              [pTooltip]="'general.tooltips.manageFilters' | translate"
              tooltipPosition="left"
              (click)="filterManagement.toggle($event)"
              [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
              icon="pi pi-bars"></button>
            <button
              pButton
              pRipple
              [pTooltip]="'general.tooltips.saveFilters' | translate"
              tooltipPosition="left"
              [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
              (click)="filterCreate.toggle($event)"
              icon="pi pi-save"></button>
            <button
              pButton
              pRipple
              [pTooltip]="'general.tooltips.removeFilter' | translate"
              tooltipPosition="left"
              [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
              (click)="clearFilters()"
              icon="pi pi-filter-slash"></button>
          </div>
          <p-overlayPanel
            #filterManagement
            (onShow)="displayFilterOverlay = $event">
            <app-filter
              #appFilter
              table="customer"
              (filterApplied)="filterApplied($event)"></app-filter>
          </p-overlayPanel>
          <p-overlayPanel #filterCreate>
            <app-filter-create
              (saveFilterClicked)="saveFilterClicked($event);filterCreate.toggle($event)"></app-filter-create>
          </p-overlayPanel>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-customer>
      <tr
        [ngClass]="{
        'inactive-customer': !customer.isActive
        }">
        <td alignFrozen="left" pFrozenColumn>
          <app-customer-status-badge
            [customer]="customer"
            [clickable]="customerBadgeClickable"
            [disabled]="!$can('update')"
            (changeIsBlocked)="changeIsBlocked($event, customer)" />
        </td>

        <td>
          <span
            *ngFor="let heatingEngineer of customer.virtual.virtualData.heatingEngineers; last as isLast"
            [pTooltip]="heatingEngineer.deviceSerialNumbers">
            <!-- Do not format this code or else it will insert a space and that looks bad -->
            <span>{{ heatingEngineer.name }}</span>
            <span *ngIf="!isLast">;&nbsp;</span>
          </span>
        </td>

        <td>
          <div class="flex flex-column">
            <span>
              {{ ("customerComponent.facilityAddressType." +
              customer.facilityAddressType) | translate }}
            </span>
            <span
              [pTooltip]="customer.virtual.facilityAddress"
              class="cut-text"
              [ngStyle]="{
                  'max-width': '500px',
                  'min-width': '300px',
               }">
              {{ customer.virtual.facilityAddress }}
            </span>
          </div>
        </td>
        <td>{{ customer.virtual.contactPerson }}</td>
        <td>
          <a
            routerLink="/customers/{{customer.id}}"
            [queryParams]="{'tab': '3'}"
            class="link">
            {{customer.virtual.ticketCount}}
          </a>
        </td>
        <td>
          <a
            routerLink="/customers/{{customer.id}}"
            [queryParams]="{'tab': '2'}"
            class="link">
            {{customer.virtual.deviceCount}}
          </a>
        </td>

        <td>
          <span
            *ngFor="let deviceCtx of customer.virtual.virtualData.devices; last as isLast"
            [pTooltip]="deviceCtx.serialNumbers">
            <!-- Do not format this code or else it will insert a space and that looks bad -->
            <span>{{ deviceCtx.deviceName }}</span>
            <span *ngIf="!isLast">;&nbsp;</span>
          </span>
        </td>

        <td>
          <div class="flex flex-column">
            <span
              [pTooltip]="customer.virtual.deviceSerialNumbers"
              class="cut-text device-serial-numbers-text">
              {{ customer.virtual.deviceSerialNumbers }}
            </span>
          </div>
        </td>
        <td>
          <span
            *ngFor="let customerDevice of customer.virtual.virtualData.gasSeparatorCheckDevices; last as isLast"
            [pTooltip]="displaySerialNumber(customerDevice)">
            <!-- Do not format this code or else it will insert a space and that looks bad -->
            <span>{{ displayIsGasSeparatorInstalled(customerDevice) }}</span>
            <span *ngIf="!isLast">;&nbsp;</span>
          </span>
        </td>
        <td alignFrozen="right" pFrozenColumn>
          <div class="flex gap-2">
            <app-table-action
              *ngIf="$can('update') && showEditAction"
              (actionClicked)="updateCustomer(customer)"
              [icon]="'pi pi-pencil'"
              [severity]="'success'"
              [pTooltip]="'customerComponent.actions.tooltips.edit' | translate"></app-table-action>

            <app-table-action
              *ngIf="$can('read') && !$can('update')"
              (actionClicked)="viewCustomer(customer)"
              [icon]="'pi pi-eye'"
              [severity]="'info'"
              [pTooltip]="'customerComponent.actions.tooltips.view' | translate"></app-table-action>

            <app-table-action
              *ngIf="$can('delete') && showDeleteAction"
              (actionClicked)="deleteCustomer(customer)"
              [icon]="'pi pi-trash'"
              [severity]="'danger'"
              [pTooltip]="'customerComponent.actions.tooltips.delete' | translate"></app-table-action>

            <app-table-action
              *ngIf="$can('create') && showCorrespondenceAction"
              (actionClicked)="newCorrespondence(customer)"
              [icon]="'pi pi-comments'"
              [severity]="'primary'"
              tooltipPosition="left"
              [pTooltip]="'customerComponent.actions.tooltips.newCorrespondence' | translate"></app-table-action>

            <app-table-action
              *ngIf="$canTicket('create') && showTicketAction"
              (actionClicked)="newTicket(customer)"
              [icon]="'pi pi-check-square'"
              [severity]="'primary'"
              tooltipPosition="left"
              [pTooltip]="'customerComponent.actions.tooltips.newTicket' | translate"></app-table-action>

            <button
              pButton
              pRipple
              *ngIf="$can('read') && showChooseAction"
              [pTooltip]="'customerComponent.actions.tooltips.choose' | translate"
              [style]="'height: 2rem!important;'"
              tooltipPosition="left"
              (click)="chooseCustomer(customer)"
              [outlined]="true"
              [raised]="true"
              [label]="'primeng.choose' | translate"></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
