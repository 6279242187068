<app-form-table
  *ngIf="!loading"
  [showTechnicianColumn]="showTechnicianColumn"
  [showCreatedAtColumn]="showCreatedAtColumn"
  [smallTable]="smallTable"
  [deadlineEditable]="deadlineEditable"
  [isSelectable]="isSelectable"
  [showDeleteAction]="showDeleteAction && $can('delete')"
  [showPdfAction]="showPdfAction"
  [showEditAction]="showEditAction && $can('update')"
  [showSelectedFormsOnly]="showSelectedFormsOnly"
  [showGlobalSearch]="showGlobalSearch"
  [showCreateAction]="showCreateAction && $can('create')"
  [selectionDisabled]="selectionDisabled"
  (selectedFormsChange)="onSelectedFormsChange($event)"
  (deadlineChange)="onDeadlineInlineChange($event)"
  [(selectedForms)]="selectedForms"
  [showStatusColumn]="showStatusColumn"
  [showCompletedAtColumn]="showCompletedAtColumn"
  [ticket]="ticket"
  (ticketUpdated)="onTicketUpdated($event)"
  [currentAppointmentId]="currentAppointmentId"
  (handleCreateForm)="handleCreateForm()"
  (handleUpdateForm)="handleUpdateForm($event)"
  (handleDeleteForm)="handleDeleteForm($event)"></app-form-table>

<p-sidebar
  *ngIf="isSidebarVisible && !loading"
  [visible]="isSidebarVisible && !loading"
  position="right"
  appendTo="body"
  styleClass="w-32rem scrollbar-gutter-stable"
  (visibleChange)="onSidebarVisibleChange($event)"
  (onHide)="onSidebarVisibleChange(false)">
  <!-- Header | Create Sidebar -->
  <ng-template pTemplate="header">
    <div class="bg-primary flex justify-content-between">
      <h4 class="mb-0 ml-4 text-white">
        {{ ( 'general.form.createSubject') | translate: {subject:
        ("formComponent.title" | translate)} }}
      </h4>
    </div>
  </ng-template>
  <!-- Body | Create Sidebar -->
  <ng-template pTemplate="content">
    <div body class="pt-4 ml-4">
      <!-- forms Field -->
      <div class="form-field mb-2">
        <label htmlFor="type" class="block">
          {{ "formComponent.attributes.forms" | translate}}
        </label>
        <p-dropdown
          [options]="formTypes"
          [ngModel]="hiveForm.type"
          optionLabel="label"
          class="w-full mt-2 block"
          (onChange)="onFormTypeChange($event)"
          [placeholder]="'general.form.chooseSubject'| translate:
      {subject: ('formComponent.attributes.forms' | translate)}">
          <ng-template let-item pTemplate="item">
            <div class="inline-block vertical-align-middle">
              {{ 'formComponent.attributes.formTypes.' + item | translate}}
            </div>
          </ng-template>
          <ng-template let-selectedItem pTemplate="selectedItem">
            <div class="inline-flex align-items-center gap-2 px-1">
              {{ 'formComponent.attributes.formTypes.' + selectedItem |
              translate}}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- customerDevice Field -->
      <div class="form-field mb-2">
        <label htmlFor="customerDevice" class="block">
          {{ "formComponent.attributes.customerDevice" | translate}}
        </label>
        <p-dropdown
          [options]="ticket.customerDevices"
          [ngModel]="hiveForm.customerDevice"
          dataKey="id"
          class="w-full mt-2 block"
          (onChange)="onCustomerDeviceChange($event)"
          [placeholder]="'general.form.chooseSubject'| translate:
          {subject: ('formComponent.attributes.customerDevice' | translate)}">
          <ng-template let-item pTemplate="item">
            <div class="inline-block vertical-align-middle">
              <div class="font-bold">{{item.device.title}}</div>
              <div>
                {{item.internalDeviceSerialNumber}};
                {{item.externalDeviceSerialNumber}}
              </div>
            </div>
          </ng-template>
          <ng-template let-selectedItem pTemplate="selectedItem">
            <div class="font-bold">{{selectedItem.device.title}}</div>
            <div>
              {{selectedItem.internalDeviceSerialNumber}};
              {{selectedItem.externalDeviceSerialNumber}}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- technician field -->
      <div
        class="form-field mb-2"
        *ngIf="showTechnicianInSidebar && hiveForm.id">
        <label htmlFor="technician" class="block">
          {{ "formComponent.attributes.technician" | translate}}
        </label>
        <p-dropdown
          *ngIf="users"
          [options]="users"
          [ngModel]="hiveForm.technician"
          dataKey="id"
          optionLabel="filterOptionLabel"
          [filter]="true"
          class="w-full mt-2 block"
          (onChange)="onTechnicianChange($event)"
          [placeholder]="'general.form.chooseSubject'| translate:
          {subject: ('formComponent.attributes.technician' | translate)}">
          <ng-template let-item pTemplate="item">
            <div
              class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
              <app-user-avatar [user]="item" size="normal" />
              <span>{{item.firstname}} {{item.lastname}}</span>
            </div>
          </ng-template>
          <ng-template let-selectedItem pTemplate="selectedItem">
            <div
              *ngIf="selectedItem"
              class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
              <app-user-avatar [user]="selectedItem" size="normal" />
              <span>{{selectedItem.firstname}} {{selectedItem.lastname}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- deadline field -->
      <div class="form-field mb-2" *ngIf="showDeadlineInSidebar && hiveForm.id">
        <label htmlFor="deadline" class="block">
          {{ "formComponent.attributes.deadline" | translate}}
        </label>
        <p-calendar
          class="w-full mt-2 block"
          styleClass="w-full"
          appendTo="body"
          [(ngModel)]="hiveForm.deadline"
          (onSelect)="onDeadlineChange($event)"
          dateFormat="dd.mm.yy"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [showTime]="true"
          [required]="true"
          inputId="start"
          [firstDayOfWeek]="1"
          [hideOnDateTimeSelect]="false"></p-calendar>
      </div>
    </div>
  </ng-template>
  <!-- Footer | Create Sidebar -->
  <ng-template pTemplate="footer">
    <app-logging
      #loggingComponent
      *ngIf="hiveForm && hiveForm.id"
      [model]="'Form'"
      [identifier]="hiveForm.id"
      [config]="config"
      [formatter]="loggingFormatter" />

    <div class="flex flex-wrap gap-2 justify-content-between mt-2">
      <button
        pButton
        pRipple
        type="button"
        [label]="'general.form.cancel' | translate"
        (click)="onSidebarVisibleChange(false)"
        class="flex-auto p-button-outlined"></button>
      <button
        pButton
        pRipple
        [label]="'general.form.save' | translate"
        class="flex-auto"
        [disabled]="createSubmitDisabled"
        (click)="onSubmit()"></button>
    </div>
  </ng-template>
</p-sidebar>
