import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, catchError, first, throwError } from 'rxjs';
import { DataModificationMethod } from 'src/app/enums/DataModificationMethod';
import { Country } from 'src/app/models/Country';
import {
  ClientType,
  Ticket,
  TicketCreate,
  TicketStatus
} from 'src/app/models/Ticket';
import { Customer } from 'src/app/models/customer/Customer';
import { CountryService } from 'src/app/services/api/country.service';
import { CustomerService } from 'src/app/services/api/customer/customer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import { AppAction } from 'src/config/authorization.config';
import { TicketCreateFormComponent } from './components/ticket-create-form/ticket-create-form.component';
import { TicketService } from 'src/app/services/api/ticket.service';
import {
  statusChipsArchive,
  statusChipsCurrent
} from './components/utils/getTicketInfo';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class TicketComponent implements OnInit, OnChanges {
  @ViewChild('createForm')
  formComponent?: TicketCreateFormComponent;

  @Input() customer: Customer | undefined;

  @Input() showCustomerColumn = true;

  @Input() standaloneCard = false;

  @Input() createOnly = false;

  @Input() showCreateButton = true;

  @Input() triggerCreate = false;

  @Input() tickets: Ticket[] | undefined;

  @Input() ticketsArchive: Ticket[] | undefined;

  @Input() ticketsCurrent: Ticket[] | undefined;

  @Output() ticketCreated = new EventEmitter<Ticket>();

  @Output() ticketCreateCancelled = new EventEmitter<void>();

  subscriptions = new Subscription();

  countries: Country[] = [];

  customers: Customer[] = [];

  isSidebarVisible = false;

  ticketToCreate!: TicketCreate;

  getCustomersIsLoading = true;

  activeTabIndex = 0;

  statusChipsArchive = statusChipsArchive;

  statusChipsCurrent = statusChipsCurrent;

  constructor(
    public readonly translate: TranslateService,
    public readonly messageCenterService: MessageCenterService,
    private readonly countryService: CountryService,
    public readonly customerService: CustomerService,
    public readonly ticketService: TicketService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.ticketToCreate = this.ticketFactory();
    this.subscriptions.add(
      this.countryService
        .findAll()
        .pipe(first())
        .subscribe((countries) => {
          this.countries = countries;
        })
    );

    this.subscriptions.add(
      this.customerService
        .findAll()
        .pipe(first())
        .subscribe((customers) => {
          this.customers = customers;
          this.getCustomersIsLoading = false;
        })
    );
    if (this.tickets || this.createOnly) {
      this.splitTickets();

      return;
    }

    if (this.customer) {
      this.subscriptions.add(
        this.ticketService
          .findAllByCustomerId(this.customer.id)
          .pipe(first())
          .subscribe((tickets) => {
            this.tickets = tickets;
            this.splitTickets();
          })
      );
    } else {
      this.subscriptions.add(
        this.ticketService
          .findAll()
          .pipe(first())
          .subscribe((tickets) => {
            this.tickets = tickets;
            this.splitTickets();
          })
      );
    }
  }

  ngOnChanges(): void {
    if (this.triggerCreate) {
      this.handleCreateTicket();
    }
  }

  splitTickets(): void {
    if (this.tickets) {
      this.ticketsArchive = this.tickets.filter(
        (ticket) =>
          ticket.ticketStatusType === TicketStatus.Completed ||
          ticket.ticketStatusType === TicketStatus.Cancelled
      );

      this.ticketsCurrent = this.tickets.filter(
        (ticket) =>
          ticket.ticketStatusType !== TicketStatus.Completed &&
          ticket.ticketStatusType !== TicketStatus.Cancelled
      );
    }
  }

  handleCreateTicket(): void {
    this.isSidebarVisible = true;
  }

  openTicketDetail(ticket?: Ticket): void {
    if (!ticket && this.formComponent) {
      const ticketCreate = this.formComponent.buildTicket();
      this.ticketService.changeTicket(ticketCreate);
    } else if (ticket) {
      this.ticketService.changeTicket(ticket);
    }
    this.router.navigate(['tickets', ticket?.id ?? 0]);
    this.isSidebarVisible = false;
  }

  handleUpdateTicket(ticket: Ticket): void {
    this.openTicketDetail(ticket);
  }

  private ticketFactory(): TicketCreate {
    return {
      subject: '',
      description: '',
      requestReason: '',
      customer: this.customer,
      isAppointmentNeeded: false,
      customerDevices: [],
      ticketCategoryType: undefined,
      ticketStatusType: undefined,
      files: [],
      clientType: ClientType.Unknown
    } satisfies TicketCreate;
  }

  get isCreateFormDirty(): boolean {
    return this.formComponent?.formGroup?.dirty || false;
  }

  get createSubmitDisabled(): boolean {
    const form = this.formComponent?.formGroup;

    if (form) {
      return !form || !form.valid;
    }

    return true;
  }

  async onSidebarVisibleChange(value: boolean): Promise<void> {
    if (value === false && this.isCreateFormDirty === false) {
      this.ticketCreateCancelled.emit();
    }

    if (value === true || this.isCreateFormDirty === false) {
      this.isSidebarVisible = value;

      return;
    }

    const isVisible = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('general.confirmUnsavedChanges.header'),
        this.translate.instant('general.confirmUnsavedChanges.message'),
        () => {
          resolve(false);
          this.ticketCreateCancelled.emit();
        },
        () => {
          resolve(true);
        }
      );
    });
    this.isSidebarVisible = isVisible;
  }

  onSubmit(): void {
    if (!this.formComponent || this.createSubmitDisabled) {
      return;
    }

    const ticket = this.formComponent.buildTicket();
    this.ticketService
      .createIncludingFiles(ticket)
      .pipe(
        catchError((error) => {
          this.showCrudToast(DataModificationMethod.Create, 'error');

          return throwError(() => error);
        }),
        first() // Automatically unsubscribe after first value
      )
      .subscribe((newTicket) => {
        this.showCrudToast(DataModificationMethod.Create, 'success', newTicket);
        if (!this.tickets) {
          this.tickets = [];
        }
        this.tickets.push(newTicket);

        this.tickets = [...this.tickets];
        this.tickets.sort((a, b) => b.id - a.id);
        this.splitTickets();
        this.ticketToCreate = this.ticketFactory();
        this.formComponent?.formGroup?.reset();
        this.isSidebarVisible = false;
        this.triggerCreate = !this.triggerCreate;
        this.ticketCreated.emit();
      });
  }

  public showCrudToast(
    method: DataModificationMethod | 'isActive' | 'isBlocked',
    severity: Severity,
    ticket?: Ticket
  ): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `ticketComponent.actions.toasts.${method}.${severity}.summary`
      ),
      this.translate.instant(
        `ticketComponent.actions.toasts.${method}.${severity}.detail`,
        {
          ticketNumber: ticket ? ticket.ticketNumber : ''
        }
      ),
      severity
    );
  }

  $can(action: AppAction) {
    return this.authService.$can(action, 'Ticket');
  }
}
