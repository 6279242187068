<form autocomplete="off" [formGroup]="form">
  <div class="form-field mb-2">
    <label htmlFor="subject" class="block">
      {{ "ticketComponent.attributes.subject" | translate}}*
    </label>
    <input
      id="subject"
      pInputText
      formControlName="subject"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.subject' | translate)}"
      [value]="ticket.subject"
      (input)="onTicketChanged()" />
  </div>
  <!-- ClientType Field -->
  <div class="form-field mb-2">
    <label htmlFor="clientType" class="block">
      {{ "ticketComponent.attributes.client" | translate}}*
    </label>
    <p-dropdown
      formControlName="clientType"
      [options]="clientTypes"
      optionLabel="label"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.client' | translate)}">
      <ng-template let-item pTemplate="item">
        <div class="inline-block vertical-align-middle">
          <span>{{'ticketComponent.clientTypes.' + item | translate}}</span>
        </div>
      </ng-template>
      <ng-template let-selectedItem pTemplate="selectedItem">
        <div class="inline-flex align-items-center gap-2 px-1">
          <span
            >{{'ticketComponent.clientTypes.' + selectedItem | translate}}</span
          >
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <!-- RequestReason Field -->
  <div class="form-field mb-2">
    <label htmlFor="requestReason" class="block">
      {{ "ticketComponent.attributes.requestReason" | translate}}
    </label>
    <textarea
      id="requestReason"
      pInputText
      formControlName="requestReason"
      type="text"
      rows="10"
      cols="30"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.requestReason' | translate)}"
      [value]="ticket.requestReason"
      (input)="onTicketChanged()"></textarea>
  </div>
  <!-- Description Field -->
  <div class="form-field mb-2">
    <label htmlFor="description" class="block">
      {{ "ticketComponent.attributes.description" | translate}}
    </label>
    <textarea
      id="description"
      pInputText
      formControlName="description"
      type="text"
      rows="10"
      cols="30"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.description' | translate)}"
      [value]="ticket.description"
      (input)="onTicketChanged()"></textarea>
  </div>
</form>
