<div class="appointment-circle absolute" (click)="onOpenDialog()">
  <i *ngIf="appointment.isCompleted" class="pi pi-check"></i>
</div>

<p-dialog
  #dialogRef
  [(visible)]="isDialogVisible"
  [header]="(appointment.isCompleted ? 'myDayView.reopenAppointment' : 'myDayView.completeAppointment') | translate"
  [modal]="true"
  [closable]="false"
  [resizable]="false">
  <ng-template pTemplate="content">
    <div class="dialog-content">
      <p>{{'myDayView.pleaseSelectTimeText' | translate}}</p>

      <div class="justify-content-center align-items-center mx-auto flex gap-2">
        <div>
          <label htmlFor="startDate" class="block">
            {{ "calendarComponent.appointment.newAppointment.fields.start" |
            translate }}
          </label>

          <p-calendar
            id="startDate"
            [(ngModel)]="selectedStartDate"
            tabindex="-1"
            [appendTo]="'body'"
            dateFormat="dd.mm.yy"
            [firstDayOfWeek]="1"
            [iconDisplay]="'input'"
            [showIcon]="true"
            [timeOnly]="true"
            [hideOnDateTimeSelect]="false"
            [required]="true"
            [showTime]="true"
            class="w-full"
            [autofocus]="false"
            [hourFormat]="'24'"
            [maxDate]="selectedEndDate">
            <ng-template
              pTemplate="inputicon"
              let-clickCallBack="clickCallBack">
              <i
                class="pi pi-clock pointer-events-none"
                (click)="clickCallBack($event)"></i>
            </ng-template>
          </p-calendar>
        </div>

        <div>
          <label htmlFor="endDate" class="block">
            {{ "calendarComponent.appointment.newAppointment.fields.end" |
            translate }}
          </label>

          <p-calendar
            id="endDate"
            [(ngModel)]="selectedEndDate"
            tabindex="-1"
            [appendTo]="'body'"
            dateFormat="dd.mm.yy"
            [firstDayOfWeek]="1"
            [iconDisplay]="'input'"
            [showIcon]="true"
            [timeOnly]="true"
            [hideOnDateTimeSelect]="false"
            [required]="true"
            [showTime]="true"
            class="w-full"
            [autofocus]="false"
            [hourFormat]="'24'"
            [minDate]="selectedStartDate">
            <ng-template
              pTemplate="inputicon"
              let-clickCallBack="clickCallBack">
              <i
                class="pi pi-clock pointer-events-none"
                (click)="clickCallBack($event)"></i>
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      type="button"
      [label]="'general.confirm.acceptLabel' | translate"
      (click)="onDialogConfirm()"
      class="flex-auto p-button-primary"></button>

    <button
      pButton
      pRipple
      type="button"
      [label]="'general.confirm.rejectLabel' | translate"
      (click)="onDialogCancel()"
      class="flex-auto p-button-outlined"></button>
  </ng-template>
</p-dialog>
