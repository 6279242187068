import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable } from 'rxjs';

import {
  Appointment,
  MarkAsCompleted,
  MyDayView
} from 'src/app/models/Appointment';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { environment } from 'src/environments/environment';

import { IsSuccessResponse } from 'src/app/types/Response';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends GenericService<Appointment> {
  constructor(
    http: HttpClient,
    messageCenterService: MessageCenterService,
    translate: TranslateService,
    private internalHttp: HttpClient
  ) {
    super(http, messageCenterService, translate);
    this.setContext('appointments');
  }

  findAllByDateRangeAndUser(
    startDate: Date,
    endDate: Date,
    userIds: number[]
  ): Observable<Appointment[]> {
    return this.internalHttp.post<Appointment[]>(
      `${environment.apiUrl}/appointments/find-all-by-date-range-and-user`,
      {
        startDate,
        endDate,
        userIds
      }
    );
  }

  public deleteWithNotes(id: number, notes: string): Observable<Appointment> {
    const body = { notes };

    return this.internalHttp
      .request<Appointment>(
        'DELETE',
        `${environment.apiUrl}/${this.context}/${id}`,
        {
          body
        }
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  getMyDayView(): Observable<MyDayView> {
    return this.internalHttp.get<MyDayView>(
      `${environment.apiUrl}/appointments/my-day-view`
    );
  }

  markAsCompleted(
    appointmentId: number,
    payload: MarkAsCompleted
  ): Observable<IsSuccessResponse> {
    return this.internalHttp
      .patch<IsSuccessResponse>(
        `${environment.apiUrl}/appointments/mark-as-completed/${appointmentId}`,
        payload
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }
}
