<div
  class="card card-extension"
  *ngIf="(config.showCsvExportAction || config.showExcelExportAction || config.showPdfExportAction) && !config.lazyLoad">
  <div class="flex">
    <div>
      <div class="mb-2">{{'table.exportTitle' | translate}}</div>
      <div>
        <button
          *ngIf="config.showCsvExportAction &&  selectedObjects.length === 0"
          type="button"
          pButton
          pRipple
          icon="pi pi-file"
          (click)="dt.exportCSV()"
          class="p-button-warning mr-2"
          pTooltip="CSV"
          tooltipPosition="bottom"></button>
        <button
          *ngIf="config.showCsvExportAction && selectedObjects.length > 0"
          type="button"
          pButton
          pRipple
          icon="pi pi-file"
          (click)="dt.exportCSV({ selectionOnly: true })"
          class="p-button-warning mr-2"
          pTooltip="CSV Selection Only"
          tooltipPosition="bottom"></button>
        <button
          *ngIf="config.showExcelExportAction"
          type="button"
          pButton
          pRipple
          icon="pi pi-file-excel"
          (click)="exportExcel()"
          class="p-button-success mr-2"
          pTooltip="XLS"
          tooltipPosition="bottom"></button>
        <button
          *ngIf="config.showPdfExportAction"
          type="button"
          pButton
          pRipple
          icon="pi pi-file-pdf"
          (click)="exportPdf()"
          class="mr-2"
          pTooltip="PDF"
          tooltipPosition="bottom"></button>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="config.showAsCard ? 'card' : ''">
  <p-table
    dataKey="id"
    #dt
    [totalRecords]="totalRecords"
    [loading]="loading"
    [lazy]="config.lazyLoad"
    (onLazyLoad)="loadData($event)"
    [value]="objectList"
    [columns]="config.columns"
    [tableStyle]="config.tableStyle ? config.tableStyle : ''"
    [styleClass]="styleClass + ' custom-scrollable-table'"
    [paginator]="true"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [filters]="tableFilters"
    [rows]="config.initialRowsCount"
    [(selection)]="selectedObjects"
    (selectionChange)="onSelectionChanged($event)"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="'table.pageReportTemplate' | translate"
    [showCurrentPageReport]="loading === false"
    [rowsPerPageOptions]="config.rowsPerPageOptions"
    [globalFilterFields]="globalFilterFields"
    [sortField]="config.defaultSortField"
    [resizableColumns]="true"
    columnResizeMode="expand"
    [sortOrder]="config.defaultSortOrder"
    stateStorage="local"
    [stateKey]="config.table + '-table-memory'">
    <ng-template pTemplate="caption" *ngIf="config.translationKey">
      <div class="flex relative align-items-center justify-content-between">
        <div>
          <span
            *ngIf="config.showGlobalFilter"
            class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              [value]="globalSearchValue"
              type="text"
              (input)="dt.filterGlobal($any($event.target).value, 'contains')"
              [placeholder]="'general.globalSearchPlaceholder' | translate" />
          </span>
        </div>
        <div
          *ngIf="environment.tableConfiguration.showTableHeader"
          class="title-container">
          <h4>{{ config.translationKey + ".table.title" | translate}}</h4>
        </div>
        <div
          *ngIf="config.topRightAction && $can(config.topRightAction.permissionAction)">
          <button
            pButton
            pRipple
            type="button"
            [icon]="'pi '+config.topRightAction.icon"
            [label]="config.topRightAction.label ? (config.topRightAction.label[0] | translate: { subject: (config.topRightAction.label[1] | translate)}) : ''"
            class="flex-auto p-button-outlined"
            (click)="onActionClicked(config.topRightAction)"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngIf="config.rowSelectionMode === 'single'"
          style="width: 4rem"
          pFrozenColumn></th>
        <th
          *ngIf="config.rowSelectionMode === 'multiple'"
          style="width: 4rem"
          pFrozenColumn>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let col of config.columns">
          <ng-container *ngIf="col.isTableField">
            <th
              *ngIf="col.isTableField && !col.frozenLeft && !col.frozenRight"
              pResizableColumn
              [style]="col.width ? 'width: '+col.width+'rem;' : 'width: 15rem;'"
              [pSortableColumn]="col.field"
              [pSortableColumnDisabled]="!col.isSortable">
              <app-generic-column-header
                [column]="col"
                [translationKey]="config.translationKey"></app-generic-column-header>
            </th>
            <th
              *ngIf="col.isTableField && col.frozenLeft && !col.frozenRight"
              pResizableColumn
              alignFrozen="left"
              pFrozenColumn
              [style]="col.width ? 'width: '+col.width+'rem;' : 'width: 15rem;'"
              [pSortableColumn]="col.field">
              <app-generic-column-header
                [column]="col"
                [translationKey]="config.translationKey"></app-generic-column-header>
            </th>
            <th
              *ngIf="col.isTableField && col.frozenRight && !col.frozenLeft"
              pResizableColumn
              alignFrozen="right"
              pFrozenColumn
              [style]="col.width ? 'width: '+col.width+'rem;' : 'width: 15rem;'"
              [pSortableColumn]="col.field">
              <app-generic-column-header
                [column]="col"
                [translationKey]="config.translationKey"></app-generic-column-header>
            </th>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="config.rowActions.length > 0">
          <th
            [ngClass]="config.size === 'sm' ? 'sm' : ''"
            [ngStyle]="{
              width: config.rowActionWidthRem ? config.rowActionWidthRem + 'rem' : undefined
            }"
            alignFrozen="right"
            pFrozenColumn>
            {{'table.actionsTitle' | translate}}
          </th>
        </ng-container>
      </tr>
      <tr *ngIf="config.table !== 'filter' && config.showFilterConfiguration">
        <ng-container *ngFor="let col of config.columns">
          <th
            *ngIf="col.frozenLeft && col.isTableField"
            pResizableColumn
            pFrozenColumn
            alignFrozen="left">
            <p-columnFilter
              *ngIf="col.isFilterable && ((!col.options && col.type !== 'boolean') || col.type === 'autocomplete')"
              field="{{col.field}}"
              [matchMode]="col.matchMode"
              [showMenu]="false">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback">
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                  [ngStyle]="{
                    minWidth: '75px',
                  }" />
              </ng-template>
            </p-columnFilter>
            <p-columnFilter
              *ngIf="col.isFilterable && col.type === 'boolean'"
              type="boolean"
              matchMode="equals"
              [field]="col.field"></p-columnFilter>
            <p-columnFilter
              *ngIf="col.isFilterable && col.options && col.type !== 'autocomplete'"
              [field]="col.field"
              [matchMode]="col.matchMode"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false">
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">
                    {{ config.translationKey + ".table.columns." + col.field +
                    ".title" | translate }}</span
                  >
                </div>
              </ng-template>
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback">
                <p-multiSelect
                  [appendTo]="'body'"
                  *ngIf="col.translatePath"
                  [ngModel]="value"
                  [options]="col.options"
                  [filter]="false"
                  (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">
                    {{ col.optionLabel }}
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">
                        <ng-container *ngIf="col.optionLabel">
                          {{ option[col.optionLabel] | translate }}
                        </ng-container>
                        <ng-container *ngIf="!col.optionLabel">
                          {{ ((col.translatePath + '.' + option) | translate) }}
                        </ng-container>
                      </span>
                    </div>
                  </ng-template>
                  <ng-template let-options pTemplate="selectedItems">
                    <div
                      class="inline-flex align-items-center gap-2 px-1"
                      *ngFor="let option of options; index as i">
                      <div>
                        {{(col.translatePath + '.' + option) | translate}}{{ i
                        !== options.length - 1 ? ',' : '' }}
                      </div>
                    </div>
                    <div *ngIf="!options || options.length === 0">Select</div>
                  </ng-template>
                </p-multiSelect>

                <p-multiSelect
                  *ngIf="!col.translatePath"
                  [ngModel]="value"
                  [appendTo]="'body'"
                  [options]="col.options"
                  [optionLabel]="col.optionLabel || ''"
                  [filter]="col.showFilter"
                  (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1"
                        >{{ col.optionLabel ? option[col.optionLabel] : option
                        }}</span
                      >
                      <span class="ml-1 mt-1"
                        >{{ col.optionLabel2 ? option[col.optionLabel2] : ''
                        }}</span
                      >
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th pResizableColumn *ngIf="!col.frozenLeft && col.isTableField">
            <p-columnFilter
              *ngIf="col.isFilterable && ((!col.options && col.type !== 'boolean'&& col.type !== 'date') || col.type === 'autocomplete')"
              field="{{col.field}}"
              [matchMode]="col.matchMode"
              [showMenu]="false">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback">
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  class="p-inputtext"
                  [ngStyle]="{
                    minWidth: '75px',
                  }" />
              </ng-template>
            </p-columnFilter>
            <p-columnFilter
              *ngIf="col.isFilterable && col.type === 'date'"
              field="{{col.field}}"
              [showMenu]="false"
              matchMode="date">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback">
                <p-calendar
                  [showTime]="false"
                  [dateFormat]="'dd.mm.yy'"
                  [ngModel]="value"
                  (ngModelChange)="filter($event)"
                  appendTo="body"
                  selectionMode="range"
                  [readonlyInput]="true"
                  [style]="{ width: '12rem' }" />
              </ng-template>
            </p-columnFilter>
            <p-columnFilter
              *ngIf="col.isFilterable && col.type === 'boolean'"
              type="boolean"
              matchMode="equals"
              [field]="col.field"></p-columnFilter>
            <p-columnFilter
              *ngIf="col.isFilterable && col.options && col.type !== 'autocomplete'"
              [field]="col.field"
              [matchMode]="col.matchMode"
              [showMenu]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false">
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">
                    {{ config.translationKey + ".table.columns." + col.field +
                    ".title" | translate }}</span
                  >
                </div>
              </ng-template>
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback">
                <p-multiSelect
                  [appendTo]="'body'"
                  *ngIf="col.translatePath"
                  [ngModel]="value"
                  [options]="col.options"
                  [filter]="false"
                  (onChange)="filter($event.value)"
                  [style]="{
                    maxWidth: '250px',
                  }">
                  <ng-template let-option pTemplate="item">
                    {{ col.optionLabel }}
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">
                        {{ (col.optionLabel ? (col.translatePath + '.' +
                        option[col.optionLabel]) : (col.translatePath + '.' +
                        option)) | translate}}
                      </span>
                    </div>
                  </ng-template>
                  <ng-template
                    let-value
                    pTemplate="selectedItems"
                    *ngIf="col.field === 'role'">
                    <div
                      class="inline-flex align-items-center gap-2 px-1"
                      *ngFor="let option of value; index as i">
                      <div>
                        {{(col.translatePath + '.' + option) | translate}}{{ i
                        !== value.length - 1 ? ',' : '' }}
                      </div>
                    </div>
                    <div *ngIf="!value || value.length === 0">Select</div>
                  </ng-template>
                </p-multiSelect>

                <p-multiSelect
                  *ngIf="!col.translatePath"
                  [ngModel]="value"
                  [appendTo]="'body'"
                  [options]="col.options"
                  [optionLabel]="col.optionLabel || ''"
                  [filter]="col.showFilter"
                  (onChange)="filter($event.value)">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1"
                        >{{ col.optionLabel ? option[col.optionLabel] : option
                        }}</span
                      >
                      <span class="ml-1 mt-1"
                        >{{ col.optionLabel2 ? option[col.optionLabel2] : ''
                        }}</span
                      >
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
        </ng-container>
        <ng-container
          *ngIf="config.rowActions.length > 0 && saveTableFiltersEnabled && config.table !== 'filter'">
          <th alignFrozen="right" pFrozenColumn>
            <div class="flex gap-2">
              <button
                pButton
                pRipple
                [pTooltip]="'general.tooltips.manageFilters' | translate"
                tooltipPosition="left"
                *ngIf="saveTableFiltersEnabled"
                (click)="filterManagement.toggle($event)"
                [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
                icon="pi pi-bars"></button>
              <button
                pButton
                pRipple
                [pTooltip]="'general.tooltips.saveFilters' | translate"
                tooltipPosition="left"
                *ngIf="saveTableFiltersEnabled"
                [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
                (click)="filterCreate.toggle($event)"
                icon="pi pi-save"></button>
              <button
                pButton
                pRipple
                [pTooltip]="'general.tooltips.removeFilter' | translate"
                tooltipPosition="left"
                *ngIf="saveTableFiltersEnabled"
                [style]="'border-radius: 50%; width: 2rem !important;height: 2rem!important;'"
                (click)="clearFilters()"
                icon="pi pi-filter-slash"></button>
            </div>
            <p-overlayPanel
              #filterManagement
              (onShow)="onOverlayDisplayChange()">
              <app-filter
                #appFilter
                [table]="config.table"
                (filterApplied)="filterApplied($event)"></app-filter>
            </p-overlayPanel>
            <p-overlayPanel #filterCreate>
              <app-filter-create
                (saveFilterClicked)="saveFilterClicked($event);filterCreate.toggle($event)"></app-filter-create>
            </p-overlayPanel>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-object>
      <tr>
        <ng-container [ngSwitch]="config.rowSelectionMode"> </ng-container>
        <td *ngIf="config.rowSelectionMode === 'single'" pFrozenColumn>
          <p-tableRadioButton [value]="object"></p-tableRadioButton>
        </td>
        <td *ngIf="config.rowSelectionMode === 'multiple'" pFrozenColumn>
          <p-tableCheckbox [value]="object"></p-tableCheckbox>
        </td>
        <ng-container *ngFor="let col of config.columns">
          <td *ngIf="col.isTableField && !col.frozenLeft && !col.frozenRight">
            <app-generic-column-body
              [config]="config"
              [column]="col"
              [value]="object[col.field]"
              [object]="object"
              [field]="col.field"
              (columnClickedEvent)="onColumnClicked($event)"
              (switchValueChanged)="onCellChanged($event, col.field)"></app-generic-column-body>
          </td>
          <td
            *ngIf="col.isTableField && col.frozenLeft && !col.frozenRight"
            alignFrozen="left"
            pFrozenColumn>
            <app-generic-column-body
              [config]="config"
              [column]="col"
              [value]="object[col.field]"
              [object]="object"
              [field]="col.field"
              (columnClickedEvent)="onColumnClicked($event)"
              (switchValueChanged)="onCellChanged($event, col.field)"></app-generic-column-body>
          </td>
          <td
            *ngIf="col.isTableField && col.frozenRight && !col.frozenLeft"
            alignFrozen="right"
            pFrozenColumn>
            <app-generic-column-body
              [config]="config"
              [column]="col"
              [value]="object[col.field]"
              [object]="object"
              [field]="col.field"
              (columnClickedEvent)="onColumnClicked($event)"
              (switchValueChanged)="onCellChanged($event, col.field)"></app-generic-column-body>
          </td>
        </ng-container>
        <ng-container *ngIf="config.rowActions.length > 0">
          <td alignFrozen="right" pFrozenColumn>
            <div
              class="flex gap-2"
              [ngStyle]="{
              maxWidth: config.rowActionWidthRem ? config.rowActionWidthRem + 'rem' : 'auto'
              }">
              <ng-container *ngFor="let action of config.rowActions">
                <ng-container *ngIf="$can(action.permissionAction)">
                  <ng-container [ngSwitch]="action.iconOnly">
                    <ng-container *ngIf="action.assetPath && !action.fullColor">
                      <button
                        *ngSwitchCase="false"
                        pButton
                        pRipple
                        [pTooltip]="action.tooltipTranslateKey | translate"
                        tooltipPosition="top"
                        [icon]="'pi ' + action.icon"
                        [style]="'background: ' + 'url(' + action.assetPath + ')' + '!important;    background-repeat: no-repeat !important;background-size: cover !important;background-position: center;color: ' + action.textColor + '!important;border-color: '+ action.color+ '!important;width: 2rem !important;height: 2rem!important;'"
                        class="p-button-rounded mr-2"
                        (click)="onActionClicked(action, object)"></button>
                    </ng-container>
                    <ng-container
                      *ngIf="!action.assetPath  && action.fullColor">
                      <span
                        *ngSwitchCase="true"
                        [pTooltip]="action.tooltipTranslateKey | translate"
                        tooltipPosition="top"
                        [style]="'color: ' + action.color + ' !important'"
                        [className]="'text-primary cursor-pointer mr-3 pi ' + action.icon"
                        (click)="onActionClicked(action, object)"></span>
                      <button
                        *ngSwitchCase="false"
                        pButton
                        pRipple
                        [pTooltip]="action.tooltipTranslateKey | translate"
                        tooltipPosition="top"
                        [icon]="'pi ' + action.icon"
                        [style]="'background-color: ' + action.color + '!important;color: ' + action.textColor + '!important;border-color: '+ action.color+ '!important;width: 2rem !important;height: 2rem!important;'"
                        class="p-button-rounded mr-2"
                        (click)="onActionClicked(action, object)"></button>
                      <span *ngSwitchDefault></span>
                    </ng-container>
                    <ng-container *ngIf="!action.fullColor">
                      <app-table-action
                        (actionClicked)="onActionClicked(action, object)"
                        [icon]="'pi ' + action.icon"
                        [severity]="action.severity"
                        [pTooltip]="action.tooltipTranslateKey | translate"></app-table-action>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <ng-container *ngIf="config.bottomLeftAction"> test </ng-container>
    </ng-template>
    <ng-template pTemplate="paginatorright">
      <ng-container *ngIf="config.bottomRightAction"> test </ng-container>
    </ng-template>
    <ng-template pTemplate="summary" *ngIf="config.showSummary">
      <p class="p-2 text-center">
        {{config.translationKey+'.table.summary' | translate}}
      </p>
    </ng-template>
  </p-table>
</div>
