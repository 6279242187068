import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';

import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import {
  LogAction,
  LogAttributeType,
  Logging,
  LoggingAttribute,
  LoggingModel,
  LoggingView
} from 'src/app/models/Logging';
import { LoggingService } from 'src/app/services/logging.service';
import { LoggingFormatter } from 'src/app/utils/logging';
import { getTimestampDisplay } from 'src/app/utils/other/date.utils';
import { environment } from 'src/environments/environment';
import { isFloat } from 'src/utils/string-checker';

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss'],
  providers: [LoggingService]
})
export class LoggingComponent implements OnInit, OnDestroy {
  @Input({ required: true }) identifier!: number;

  @Input({ required: true }) model!: LoggingModel;

  @Input({ required: true }) config!: GenericTableConfiguration;

  @Input() formatter: LoggingFormatter | null = null;

  @Input() tableView = false;

  loggingSubscription: Subscription | null = null;

  loggingView: LoggingView | null = null;

  filteredEntries: LoggingView['loggingEntries'] = [];

  isVisible = false;

  expandedRows: Record<string, boolean> = {};

  tableConfig = environment.tableConfiguration;

  searchTerm = '';

  private searchTermSubject = new BehaviorSubject<string>('');

  private searchTermSubscription: Subscription | null = null;

  constructor(
    private loggingService: LoggingService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchLoggingView();

    this.searchTermSubscription = this.searchTermSubject.subscribe(
      (searchTerm) => {
        this.searchTerm = searchTerm;
        this.filteredEntries = this.filterEntries();

        if (!this.searchTerm) {
          this.expandedRows = {};
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.loggingSubscription?.unsubscribe();
    this.searchTermSubscription?.unsubscribe();
  }

  fetchLoggingView(): void {
    this.loggingSubscription?.unsubscribe();

    this.loggingSubscription = this.loggingService
      .get(this.model, this.identifier)
      .subscribe((loggingView: LoggingView) => {
        this.loggingView = loggingView;
        this.filteredEntries = this.filterEntries();
      });
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTermSubject.next(searchTerm);
  }

  formatLoggingValue(
    mode: 'new' | 'old',
    attribute: LoggingAttribute,
    log: Logging
  ): string {
    const value = mode === 'new' ? attribute.newValue : attribute.oldValue;
    const type =
      mode === 'new' ? attribute.newValueType : attribute.oldValueType;

    const formatter = this.formatter?.get(attribute.attributeKey);

    if (formatter) {
      return formatter(value, type, mode, attribute, log);
    }

    switch (type) {
      case LogAttributeType.DateTime: {
        const date = moment(value);

        return date.format('DD.MM.YYYY HH:mm:ss');
      }

      case LogAttributeType.Null:
      case LogAttributeType.Undefined:
        return '';

      case LogAttributeType.Boolean: {
        if (value === 'true') {
          return this.translate.instant('general.yes');
        }

        if (value === 'false') {
          return this.translate.instant('general.no');
        }

        return value;
      }

      case LogAttributeType.Number: {
        if (isFloat(value)) {
          return parseFloat(value).toFixed(2);
        }

        return value;
      }
      case LogAttributeType.JSON:
        // prettify JSON
        return JSON.stringify(JSON.parse(value), null, 2);

      case LogAttributeType.BigInt:
      case LogAttributeType.String:
      default:
        return value;
    }
  }

  getAttributeFieldDisplay(attribute: string): string {
    return this.translate.instant(
      `${this.config.translationKey}.table.columns.${attribute}.title`
    );
  }

  getActionDisplay(log: Logging): string {
    if (log.action === 'Text') {
      return log.context ?? 'Error: No context';
    }

    const translation = this.translate.instant(
      `logging.logAction.${log.action}`
    );

    if (
      log.action === LogAction.FileUpload ||
      log.action === LogAction.FileDelete
    ) {
      return `${translation} - ${log.context}`;
    }

    return translation;
  }

  getTimestamp(timestamp: string): string {
    return getTimestampDisplay(timestamp);
  }

  private filterEntries(): LoggingView['loggingEntries'] {
    if (!this.loggingView) {
      return [];
    }

    if (!this.searchTerm) {
      return this.loggingView.loggingEntries;
    }

    return this.loggingView.loggingEntries.filter((entry) => {
      let inMatchingAttribute = false;

      for (const attribute of entry.attributes) {
        if (
          this.filterIncludes(
            this.getAttributeFieldDisplay(attribute.attributeKey)
          )
        ) {
          inMatchingAttribute = true;
          break;
        }

        if (
          this.filterIncludes(this.formatLoggingValue('new', attribute, entry))
        ) {
          inMatchingAttribute = true;
          break;
        }

        if (
          this.filterIncludes(this.formatLoggingValue('old', attribute, entry))
        ) {
          inMatchingAttribute = true;
          break;
        }
      }

      this.expandedRows[entry.id] = inMatchingAttribute;

      if (inMatchingAttribute === true) {
        return true;
      }

      if (this.filterIncludes(this.getActionDisplay(entry))) {
        return true;
      }

      if (this.filterIncludes(getTimestampDisplay(entry.timestamp))) {
        return true;
      }

      if (entry.responsibleUser) {
        if (
          this.filterIncludes(
            `${entry.responsibleUser.firstname} ${entry.responsibleUser.lastname}`
          )
        ) {
          return true;
        }
      } else if (this.filterIncludes(entry.responsibleUserCredentials)) {
        return true;
      }

      if (
        this.filterIncludes(
          moment(entry.timestamp).format('DD.MM.YYYY HH:mm:ss')
        )
      ) {
        return true;
      }

      return false;
    });
  }

  private filterIncludes(value: string): boolean {
    const searchTerm = this.searchTerm.toLowerCase();

    return value.toLowerCase().includes(searchTerm);
  }
}
