<ng-container class="flex" *ngIf="!isLoading">
  <div class="flex">
    <!-- Global Search -->
    <div class="pb-2">
      <input
        pInputText
        [(ngModel)]="searchTerm"
        [placeholder]="'general.globalSearchPlaceholder' | translate" />
    </div>
    <div class="pl-2">
      <p-button icon="pi pi-filter" (onClick)="filterPanel.toggle($event)" />
    </div>
    <!-- UploadComponent -->
    <div class="pl-2 pb-2" *ngIf="showUploadComponent">
      <p-button icon="pi pi-plus" (onClick)="op.toggle($event)" />
      <p-overlayPanel #op>
        <app-file-upload
          #fileUploadComponent
          (upload)="onUpload($event)"
          (choose)="onSelect($event)"
          [showCancelButton]="true"
          [showUploadButton]="showUploadBtn">
          <p-button
            *ngIf="showCustomButton && customButtonIcon"
            (onClick)="customUploadButtonClicked.emit()"
            [icon]="customButtonIcon"
            iconPos="left"
        /></app-file-upload>
      </p-overlayPanel>
    </div>
  </div>

  <div
    *ngIf="!isLoading"
    class="files-container"
    [ngStyle]="{
    maxHeight: maxHeight ? maxHeight + 'px' : undefined,
  }">
    <div
      *ngFor="
      let file of filesList |
      fileFilter:searchTerm |
      createdAtFilter:createdAtFilter |
      createdByFilter:createdByFilter |
      filenameFilter:fileNameFilter">
      <ng-container *ngIf="file.id && !file.deletedAt">
        <div class="outer-file-container">
          <div class="file-container flex gap-2 mb-2">
            <p-checkbox
              *ngIf="selectionMode === 'multiple'"
              [binary]="true"
              [ngModel]="file.selected"
              (onChange)="_fileSelectionChanged(file)"></p-checkbox>
            <div class="file-icon">
              <!-- Image File Icon -->
              <span
                [ngClass]="{'cursor-pointer': delegatePreview}"
                (mousedown)="delegatePreview && preview.emit({
              type: 'image', file
              })">
                <p-image
                  appendTo="body"
                  #imagePreview
                  class="prime-image-preview"
                  *ngIf="
              file.mimetype === FileTypes.JPG ||
              file.mimetype === FileTypes.PNG ||
              file.mimetype === FileTypes.GIF ||
              file.mimetype === FileTypes.SVG"
                  [src]="file.previewUrl"
                  [preview]="delegatePreview === false"
                  alt="Image"
                  width="40" />
              </span>

              <!-- Default File Icon -->
              <div
                class="icon-overlay"
                *ngIf="![
              FileTypes.PDF,
              FileTypes.DOC ,
              FileTypes.DOCX,
              FileTypes.XLS,
              FileTypes.XLSX,FileTypes.JPG,
              FileTypes.PNG, FileTypes.GIF,
              FileTypes.SVG
              ].includes(file.mimetype)">
                <i
                  class="pi pi-eye"
                  style="font-size: 2rem"
                  (mousedown)="delegatePreview ? preview.emit({
                  file, type: 'msg'
                })  : showMsgPreview(file) "></i>
                <i class="pi pi-file" style="font-size: 2rem"></i>
              </div>
              <!-- PDF File Icon -->
              <div class="icon-overlay">
                <i
                  role="button"
                  class="pi pi-eye"
                  style="font-size: 2rem"
                  (mousedown)="delegatePreview ? preview.emit({
                  file, type: 'pdf'
                }) : openFilePreview(file, 'pdf')"></i>

                <i
                  *ngIf="file.mimetype === FileTypes.PDF"
                  class="pi pi-file-pdf"
                  style="font-size: 2rem"></i>
              </div>

              <!-- Word File Icon -->
              <i
                *ngIf="file.mimetype === FileTypes.DOC || file.mimetype === FileTypes.DOCX"
                class="pi pi-file-word"
                style="font-size: 2rem"></i>

              <!-- Excel File Icon -->
              <i
                *ngIf="file.mimetype === FileTypes.XLS || file.mimetype === FileTypes.XLSX"
                class="pi pi-file-excel"
                style="font-size: 2rem"></i>
            </div>
            <!-- File information -->
            <div class="file-description">
              <div
                class="file-name"
                [pTooltip]="file.originalname"
                tooltipPosition="top">
                {{ file.originalname }}
              </div>
              <div
                class="flex align-items-center gap-2 justify-content-between">
                <div class="flex align-items-center pt-2">
                  <div class="mr-2">
                    <app-user-avatar
                      *ngIf="file.createdBy"
                      [user]="file.createdBy" />
                  </div>
                  <div class="file-date">
                    {{file.createdAt | date:'dd.MM.YYYY HH:mm:ss'}}
                  </div>
                </div>
                <div>
                  <span class="file-size">{{ displaySize(file) }}</span>
                </div>
              </div>
            </div>
            <!-- File actions - download, delete -->
            <div class="file-actions flex gap-2">
              <div
                *ngIf="file.mimetype === 'application/pdf' && !fileIsLockedByOtherUser(file)  && $can('update')"
                class="edit-file">
                <i
                  class="pi pi-file-edit icon"
                  [pTooltip]="'general.form.edit' | translate"
                  tooltipPosition="left"
                  (click)="editPdf(file)"></i>
              </div>
              <div
                *ngIf="file.mimetype === 'application/pdf' && fileIsLockedByOtherUser(file) && hasPermissionToUnlock() && $can('update')"
                class="unlock-file">
                <i
                  class="pi pi-lock-open icon"
                  [pTooltip]="'formComponent.actions.tooltips.unlock' | translate"
                  tooltipPosition="left"
                  (click)="unlockPdf(file)"></i>
              </div>
              <div class="download-file">
                <span
                  class="pi pi-download icon"
                  [pTooltip]="'general.download' | translate"
                  tooltipPosition="left"
                  (click)="download(file)"></span>
              </div>
              <div *ngIf="showRemoveBtn" class="remove-file">
                <i
                  class="pi pi-trash icon"
                  [pTooltip]="'general.form.delete' | translate"
                  tooltipPosition="left"
                  (click)="emitRemoveOnly ? fileRemoved.emit(file) : removeFile(file)"></i>
              </div>
            </div>
          </div>
          <div *ngIf="fileIsLockedByOtherUser(file)">
            <td colspan="7" class="locked-record">
              <span>
                {{ 'formComponent.formLocked.message' | translate: { lockedDate:
                (file.fileLockedAt | date: 'dd.MM.yyyy'), lockedTime:
                (file.fileLockedAt | date: 'HH:mm'), userName:
                file.fileLockedBy?.firstname + ' ' + file.fileLockedBy?.lastname
                } }}
              </span>
            </td>
          </div>
          <div *ngIf="fileIsLockedByCurrentUser(file)">
            <td colspan="7" class="locked-record-current-user">
              <span>
                {{ 'formComponent.formLocked.message' | translate: { lockedDate:
                (file.fileLockedAt | date: 'dd.MM.yyyy'), lockedTime:
                (file.fileLockedAt | date: 'HH:mm'), userName:
                file.fileLockedBy?.firstname + ' ' + file.fileLockedBy?.lastname
                } }}
              </span>
            </td>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- Dialog for file preview - (pdf) -->
<!-- ToDo: Add file preview for office files -->
<p-dialog
  [draggable]="false"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '75%' }">
  <ng-template pTemplate="headless">
    <ngx-doc-viewer
      *ngIf="fileToView && fileToView.previewUrl && viewType === 'pdf'"
      [url]="fileToView.previewUrl"
      [viewer]="viewType"
      style="width: 100%; height: 80vh"></ngx-doc-viewer>
    <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
  </ng-template>
</p-dialog>

<!-- Dialog for file preview - (msg) -->
<app-msg-viewer-modal
  *ngIf="msgFile"
  [file]="msgFile"
  (modalHidden)="msgFile = null"></app-msg-viewer-modal>

<!-- Filter Overlay Panel -->
<p-overlayPanel #filterPanel>
  <!-- Filter -->
  <div class="filter-container flex gap-2 pb-2" *ngIf="showFilterBar">
    <!-- Filter (Filename) -->
    <div class="w4 flex">
      <div>
        <input
          pInputText
          [(ngModel)]="fileNameFilter"
          [placeholder]="'general.fileFilterPlaceholders.name' | translate" />
      </div>
      <!-- Sort (Filename) -->
      <div>
        <div class="p-3">
          <span
            *ngIf="fileNameFilter"
            class="pi pi-filter-slash pi-icon ml-4"
            (click)="clearFilenameFilter()"></span>
          <span
            *ngIf="fileNameSort === 'none'"
            class="pi pi-sort-alt pi-icon ml4"
            (click)="sortFilesByName('asc')"></span>
          <span
            *ngIf="fileNameSort === 'asc'"
            class="pi pi-sort-amount-up pi-icon ml4"
            (click)="sortFilesByName('desc')"></span>
          <span
            *ngIf="fileNameSort === 'desc'"
            class="pi pi-sort-amount-down pi-icon ml4"
            (click)="sortFilesByName('none')"></span>
        </div>
      </div>
    </div>
    <!-- Filter (CreatedAt) -->
    <div class="w4 flex">
      <div>
        <p-calendar
          [firstDayOfWeek]="1"
          appendTo="body"
          [placeholder]="'general.fileFilterPlaceholders.createdAt' | translate"
          [readonlyInput]="true"
          [(ngModel)]="createdAtFilter"
          selectionMode="range"
          [dateFormat]="'dd.mm.yy'"></p-calendar>
      </div>
      <!-- Sort (CreatedAt) -->
      <div class="p-3">
        <span
          *ngIf="createdAtFilter[0] && createdAtFilter[1]"
          class="pi pi-filter-slash pi-icon ml-4"
          (click)="clearDateFilter()"></span>
        <span
          *ngIf="createdAtSort === 'none'"
          class="pi pi-sort-alt pi-icon ml4"
          (click)="sortFilesByDate('asc')"></span>
        <span
          *ngIf="createdAtSort === 'asc'"
          class="pi pi-sort-amount-up pi-icon ml4"
          (click)="sortFilesByDate('desc')"></span>
        <span
          *ngIf="createdAtSort === 'desc'"
          class="pi pi-sort-amount-down pi-icon ml4"
          (click)="sortFilesByDate('none')"></span>
      </div>
    </div>
    <!-- Filter (CreatedBy) -->
    <div class="w4 flex">
      <div>
        <p-dropdown
          [options]="createdByOptions"
          [(ngModel)]="createdByFilter"
          [showClear]="true"
          [placeholder]="'general.createdBy' | translate"
          appendTo="body">
          <ng-template let-item pTemplate="item">
            <div class="flex align-items-center">
              <app-user-avatar *ngIf="item" [user]="item" class="mr-2" />
              <div *ngIf="item">{{ item.firstname }} {{ item.lastname }}</div>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="selectedItem">
            <div class="flex align-items-center">
              <app-user-avatar *ngIf="item" [user]="item" class="mr-2" />
              <div *ngIf="item">{{ item.firstname }} {{ item.lastname }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div>
        <!-- Sort (CreatedBy) -->
        <div class="p-3">
          <span
            *ngIf="createdByFilter"
            class="pi pi-filter-slash pi-icon ml-4"
            (click)="clearCreatedByFilter()"></span>
          <span
            *ngIf="createdBySort === 'none'"
            class="pi pi-sort-alt pi-icon ml4"
            (click)="sortFilesByUser('asc')"></span>
          <span
            *ngIf="createdBySort === 'asc'"
            class="pi pi-sort-amount-up pi-icon ml4"
            (click)="sortFilesByUser('desc')"></span>
          <span
            *ngIf="createdBySort === 'desc'"
            class="pi pi-sort-amount-down pi-icon ml4"
            (click)="sortFilesByUser('none')"></span>
        </div>
      </div>
    </div>
  </div>
</p-overlayPanel>

<p-dialog
  [(visible)]="isFileModalVisible"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="false"
  [style]="{ width: '92%' }"
  (onHide)="hidePdfViewer()">
  <ng-template pTemplate="headless">
    <div>
      <div class="extra-toolbar flex gap-2 justify-content-between">
        <div class="extra-toolbar-left-container"></div>
        <div class="toolbar-caption">{{getFileName()}}</div>
        <div class="extra-toolbar-right-container flex gap-2">
          <i
            class="close-button cursor-pointer pi pi-save"
            [pTooltip]="'formComponent.formLocked.saveEditedDocument' | translate"
            (click)="export()"
            style="font-size: 1.5rem"></i>
          <i
            class="close-button cursor-pointer pi pi-times"
            [pTooltip]="'formComponent.formLocked.cancelEdit' | translate"
            (click)="cancelEdit()"
            style="font-size: 1.5rem"></i>
        </div>
      </div>
      <ngx-extended-pdf-viewer
        #pdfViewer
        *ngIf="fileURL"
        [src]="fileURL"
        [height]="'80vh'"
        [showHandToolButton]="true"
        [showOpenFileButton]="true"
        [filenameForDownload]="getFileName()">
      </ngx-extended-pdf-viewer>
    </div>

    <p-progressSpinner *ngIf="!fileURL"></p-progressSpinner>
  </ng-template>
</p-dialog>
