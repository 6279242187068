/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Observable, catchError, throwError } from 'rxjs';

import { LazyListDto } from 'src/app/models/LazyListDto';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { getHttpErrorTranslationKeys } from 'src/app/utils/errors/http-error';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenericService<T> {
  protected context!: string;

  constructor(
    private http: HttpClient,
    private messageCenterService: MessageCenterService,
    private translate: TranslateService
  ) {}

  protected setContext(context: string) {
    this.context = context;
  }

  public lazyLoad(event: TableLazyLoadEvent): Observable<LazyListDto<T>> {
    return this.http
      .post<LazyListDto<T>>(`${environment.apiUrl}/${this.context}/getLazy`, {
        lazyLoadEvent: JSON.stringify(event)
      })
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public findAll(
    config?: Parameters<typeof this.http.get>[1]
  ): Observable<T[]> {
    return this.http
      .get<T[]>(`${environment.apiUrl}/${this.context}`, config)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public delete(id: number): Observable<T> {
    return this.http
      .delete<T>(`${environment.apiUrl}/${this.context}/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public createIncludingFiles(createObject: any): Observable<T> {
    const formData = new FormData();

    if (createObject.files && createObject.files.length) {
      createObject.files.forEach((file: File) => {
        formData.append('files', file);
      });

      Reflect.deleteProperty(createObject, 'files');
    }
    formData.append('data', JSON.stringify(createObject));

    return this.http
      .post<T>(`${environment.apiUrl}/${this.context}`, formData)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public editIncludingFiles(id: number, editObject: any): Observable<T> {
    const formData = new FormData();

    if (editObject.files && editObject.files.length) {
      editObject.files.forEach((file: any) => {
        if (!file.id) {
          formData.append('files', file);
        }
      });
    }
    formData.append('data', JSON.stringify(editObject));

    return this.http
      .patch<T>(`${environment.apiUrl}/${this.context}/${id}`, formData)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public create(createObject: T): Observable<T> {
    return this.http
      .post<T>(`${environment.apiUrl}/${this.context}`, createObject)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public edit(id: number, editObject: Partial<T>): Observable<T> {
    return this.http
      .patch<T>(`${environment.apiUrl}/${this.context}/${id}`, editObject)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  public getFile(
    id: number,
    context: string,
    updatedAt?: Date
  ): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(
      `${environment.apiUrl}/${context}/download/${id}${updatedAt ? `?updatedAt=${updatedAt}` : ''}`,
      { responseType: 'arraybuffer' as 'json' }
    );
  }

  public handleError(error: HttpErrorResponse) {
    const errorKeys = getHttpErrorTranslationKeys(error);

    this.messageCenterService.showToast(
      this.translate.instant(errorKeys.summary, { status: error.status }),
      this.translate.instant(errorKeys.detail, { status: error.status }),
      'error'
    );

    return throwError(() => error);
  }
}
