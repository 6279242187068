<div class="relative">
  <app-change-appointment-status
    [appointment]="appointment"
    (updateView)="handleReload.emit()" />

  <div
    (click)="handleAppointmentClick($event)"
    class="appointment-card relative flex flex-column overflow-hidden"
    [ngStyle]="{
        opacity: appointment.isCompleted ? 0.7 : 1,
      }">
    <div class="flex flex-1">
      <div
        class="color-left relative"
        [style]="{backgroundColor: backgroundColor}">
        <div
          class="vertical-type absolute flex align-items-center justify-content-center">
          <span [class]="'pi ' + icon"></span>
          <span *ngIf="appointment.ticket">
            {{'ticketComponent.ticketChips.'+
            appointment.ticket.ticketCategoryType | translate}}
          </span>
          <span *ngIf="!appointment.ticket">
            {{'ticketComponent.table.columns.appointment.title' | translate}}
          </span>
        </div>
      </div>

      <div class="appointment-content flex-1 overflow-hidden flex flex-column">
        <div class="appointment-header flex align-items-center">
          <div class="flex-1">
            <div *ngIf="appointment.ticket">
              {{appointment.ticket.ticketNumber}}
            </div>
            <div class="font-bold">{{appointment.title}}</div>
          </div>
          <div class="appointment-header-right">
            <p-menu
              #menu
              [model]="actionItems"
              [popup]="true"
              [appendTo]="'body'">
            </p-menu>
            <p-button
              #button
              (onClick)="onMenuButtonClick($event, menu)"
              [rounded]="true"
              [text]="true"
              [severity]="'contrast'"
              class="appointment-menu-button"
              icon=" pi pi-ellipsis-v"></p-button>
          </div>
        </div>
        <div class="appointment-description flex-1">
          {{appointment.description}}
        </div>
        <div
          class="flex justify-content-between w-full flex-grow-0 flex-shrink-0">
          <div
            *ngIf="appointment.ticket?.customer"
            class="flex-1 flex flex-column align-items-start">
            <div class="appointment-customer-name font-bold">
              {{appointment.ticket?.customer?.name}}
            </div>
            <div class="appointment-customer-id">
              {{appointment.ticket?.customer?.customerNumber}}
            </div>
          </div>
          <div
            class="appointment-footer-right flex flex-column align-items-end">
            <div
              [ngStyle]="{ visibility: totalForms > 0 ? 'visible' : 'hidden' }"
              [ngClass]="checkAllFormsCompleted()"
              class="appointment-forms">
              <div class="pi pi-paperclip"></div>
              {{completedForms.length}}/{{totalForms}}
            </div>
            <div>
              <div class="pi pi-clock"></div>

              <span *ngIf="!appointment.allDay">
                {{formatTime(appointment.start)}} -
                {{formatTime(appointment.end)}}
              </span>
              <span *ngIf="appointment.allDay">
                {{'calendarComponent.appointment.newAppointment.fields.allDay' |
                translate}}
              </span>
            </div>
          </div>
        </div>
        <div
          class="appointment-technicians flex justify-content-between w-full flex-grow-0 flex-shrink-0">
          <div class="flex-1 flex flex-column align-items-start">
            <div *ngIf="appointment.technician">
              <div class="flex align-items-center">
                <app-user-avatar
                  [user]="appointment.technician"
                  size="small"
                  class="pr-2" />
                <div>
                  {{appointment.technician.firstname}}
                  {{appointment.technician.lastname}}
                </div>
              </div>
            </div>
          </div>
          <div
            class="appointment-other-technicians flex flex-column align-items-end">
            <div *ngIf="appointment.otherTechnicians">
              <div
                *ngIf="appointment.otherTechnicians?.length === 1; else multipleTechnicians"
                class="flex align-items-center">
                <app-user-avatar
                  [user]="appointment.otherTechnicians[0]"
                  size="small"
                  class="pr-2"></app-user-avatar>
                <div class="appointment-single-other-technician-name">
                  {{ appointment.otherTechnicians[0].firstname }} {{
                  appointment.otherTechnicians[0].lastname }}
                </div>
              </div>

              <ng-template #multipleTechnicians>
                <div class="flex align-items-center">
                  <ng-container
                    *ngFor="let technician of appointment.otherTechnicians">
                    <app-user-avatar
                      [user]="technician"
                      size="small"
                      class="pr-2"></app-user-avatar>
                  </ng-container>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
