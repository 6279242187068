<div [@fadeAnimation] class="container">
  <div class="card outer-card flex gap-6 w-full h-full">
    <!-- LEFT PANEL - START -->
    <div
      [ngClass]="leftPanelExpanded ? 'expanded-container-left' : 'non-expanded-container'"
      class="card-container">
      <div class="card w-full max-h-screen h-full">
        <div
          class="w-full text-lg font-bold text-primary flex gap-2 justify-content-center">
          <div>
            <span class="font-bold pi pi-calendar"></span>
          </div>
          <div class="title" *ngIf="leftPanelExpanded">
            <span
              >{{('calendarComponent.leftPanel.title' | translate) |
              uppercase}}</span
            >
          </div>
        </div>
        <ng-container *ngIf="users && leftPanelExpanded">
          <div
            cdkDropList
            (cdkDropListDropped)="dropUser($event)"
            class="pt-4 px-2 scrollable-container users">
            @for (user of users; track $index) {
            <div
              cdkDrag
              class="pb-2 ticket flex gap-1"
              [ngStyle]="{
                  '--user-color': user.color ? user.color : '#d1d5db',
                  '--user-check-color': getTextColorBasedOnBackground(user.color),
                  '--user-check-shadow': getCheckBoxShadowColor(user.color ?? '#d1d5db')
              }">
              <p-colorPicker
                appendTo="body"
                *ngIf="$canUser('update')"
                [(ngModel)]="user.color"
                (ngModelChange)="colorChanged(user)" />
              <p-checkbox
                class="custom-checkbox text-sm"
                [value]="user"
                [ngModel]="isUserSelected(user)"
                [binary]="true"
                [label]="user.firstname + ' ' + user.lastname"
                [id]="user.id"
                [disabled]="isUserCheckboxDisabled(user)"
                (onChange)="userSelected(user)"></p-checkbox>
              <span
                class="pi pi-save text-primary align-content-center"
                *ngIf="user.id && userIdsToSave.includes(user.id)"
                (click)="updateUserColor(user)"></span>
              <span
                class="pi pi-times text-primary align-content-center"
                *ngIf="user.id && userIdsToSave.includes(user.id)"
                (click)="resetUserColor(user)"></span>
            </div>
            }
          </div>
        </ng-container>
      </div>
      <div class="layout-left" (click)="calendarViewChanged('left')">
        <span
          [ngClass]="leftPanelExpanded ? 'pi pi-angle-double-left' : 'pi pi-angle-double-right'"></span>
      </div>
    </div>
    <!-- LEFT PANEL - END -->

    <!-- CENTER (CALENDAR) - START -->
    <div class="flex-grow h-full transition-width">
      <app-calendar
        id="calendar-list"
        [tickets]="ticketsForAppointment"
        [users]="users"
        [(selectedUsers)]="selectedUsers"
        [(selectedTicket)]="selectedTicket"
        (resetTicket)="resetTicket()"
        (appointmentDeleted)="reInitTickets()"
        (removeTicketFromList)="removeTicketFromList($event)"
        (reInitTickets)="reInitTickets()"
        [calendarViewChange]="calendarViewChange"></app-calendar>
    </div>

    <!-- CENTER (CALENDAR) - END -->

    <!-- RIGHT PANEL - START -->
    <div
      [ngClass]="rightPanelExpanded ? 'expanded-container-right' : 'non-expanded-container'"
      class="card-container">
      <div class="card w-full max-h-screen h-full">
        <div
          class="w-full text-lg font-bold text-primary flex gap-2 justify-content-center">
          <div>
            <span class="font-bold pi pi-clock"></span>
          </div>
          <div class="title" *ngIf="rightPanelExpanded">
            <span
              >{{('calendarComponent.rightPanel.title' | translate) |
              uppercase}}</span
            >
          </div>
        </div>
        <div class="px-2">
          <div class="flex pb-2" *ngIf="rightPanelExpanded && filteredTickets">
            <input
              id="title"
              type="text"
              class="w-full"
              pInputText
              [(ngModel)]="searchTerm"
              (input)="filterTickets()"
              [placeholder]="'general.globalSearchPlaceholder'| translate" />
            <span
              id="filter-button"
              (click)="op.toggle($event)"
              class="pi pi-filter"></span>
            <p-overlayPanel #op>
              <div class="mt-2 mb-2">
                <label htmlFor="subject" class="block mb-2 text-sm">
                  {{ "calendarComponent.rightPanel.statusPlaceholder" |
                  translate}}
                </label>
                <p-multiSelect
                  appendTo="body"
                  [options]="statusChips"
                  [ngModel]="selectedStatuses"
                  [style]="{'width':'100%'}"
                  class="w-full mt-2 block"
                  (onChange)="filterByStatus($event.value)">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <div class="flex gap-3 align-items-center">
                        <div class="flex flex-column white-space-nowrap gap-1">
                          <app-ticket-chip [label]="option"></app-ticket-chip>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template let-options pTemplate="selectedItems">
                    <div
                      class="inline-block vertical-align-middle"
                      *ngIf="options">
                      <div
                        class="inline-flex align-items-center gap-2 px-1"
                        *ngFor="let option of options; index as i">
                        {{'ticketComponent.ticketChips.'+option | translate}}
                      </div>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </div>
              <div class="mt-2 mb-2">
                <label htmlFor="subject" class="block mb-2 text-sm">
                  {{ "calendarComponent.rightPanel.categoryPlaceholder" |
                  translate}}
                </label>
                <p-multiSelect
                  appendTo="body"
                  [options]="categoryChips"
                  [ngModel]="selectedCategories"
                  [style]="{'width':'100%'}"
                  class="w-full mt-2 block"
                  (onChange)="filterByCategory($event.value)">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <div class="flex gap-3 align-items-center">
                        <div class="flex flex-column white-space-nowrap gap-1">
                          <app-ticket-chip [label]="option"></app-ticket-chip>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template let-options pTemplate="selectedItems">
                    <div
                      class="inline-block vertical-align-middle"
                      *ngIf="options">
                      <div
                        class="inline-flex align-items-center gap-2 px-1"
                        *ngFor="let option of options; index as i">
                        {{'ticketComponent.ticketChips.'+option | translate}}
                      </div>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </div>
              <div class="mt-2 mb-2">
                <label htmlFor="subject" class="block mb-2 text-sm">
                  {{ "calendarComponent.rightPanel.editorPlaceholder" |
                  translate}}
                </label>
                <p-multiSelect
                  appendTo="body"
                  [options]="users"
                  [(ngModel)]="selectedFilterUsers"
                  [style]="{'width':'100%'}"
                  class="w-full mt-2 block"
                  optionLabel="fullName"
                  (onChange)="filterByUser($event.value)">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <div class="flex gap-3 align-items-center">
                        <div
                          class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
                          <app-user-avatar [user]="option" size="normal" />
                          <span
                            >{{ option.firstname }} {{ option.lastname }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <ng-template let-options pTemplate="selectedItems">
                    <div
                      class="inline-block vertical-align-middle"
                      *ngIf="options">
                      <div class="inline-flex align-items-center gap-2 px-1">
                        <ng-container
                          *ngIf="options.length === 1; else multipleSelected">
                          <div
                            class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
                            <app-user-avatar
                              [user]="options[0]"
                              size="normal" />
                            <span
                              >{{ options[0].firstname }} {{ options[0].lastname
                              }}</span
                            >
                          </div>
                        </ng-container>
                        <ng-template #multipleSelected>
                          <div
                            class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap"
                            *ngFor="let option of options; index as i">
                            <app-user-avatar [user]="option" size="normal" />
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </div>
            </p-overlayPanel>
          </div>
        </div>
        <ng-container *ngIf="filteredTickets && rightPanelExpanded">
          <div
            class="px-2 scrollable-container"
            cdkDropList
            [cdkDropListDisabled]="!$can('create')">
            <ng-container *ngFor="let ticket of filteredTickets">
              <div
                [id]="$can('create') ? 'ticket-' + ticket.id : undefined"
                class="pb-2"
                (click)="ticketSelected(ticket)"
                [cdkDragDisabled]="!$can('create')"
                cdkDrag
                [ngClass]="{
                'ticket': $can('create'),
                'ticket-disabled': !$can('create'),
              }">
                <app-ticket-summary [ticket]="ticket"></app-ticket-summary>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container
          *ngIf="(!filteredTickets || filteredTickets.length === 0) && rightPanelExpanded">
          <div class="px-2 scrollable-container text-center">
            {{'calendarComponent.rightPanel.noTicketsAvailable' | translate}}
          </div>
        </ng-container>
      </div>

      <div class="layout-right" (click)="calendarViewChanged('right')">
        <span
          [ngClass]="rightPanelExpanded ? 'pi pi-angle-double-right' : 'pi pi-angle-double-left'"></span>
      </div>
    </div>

    <!-- RIGHT PANEL - END -->
  </div>
</div>
