<p-dialog
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [modal]="true"
  [style]="{ width: '75%' }"
  [header]="header"
  [(visible)]="display"
  [modal]="true"
  [closable]="false">
  <div class="mb-4">
    <p>
      {{ 'calendarComponent.deleteApointmentModal.description' | translate }}
    </p>
  </div>
  <div class="grid p-fluid formgrid">
    <div class="col-12 field">
      <label for="description" class="text-900 font-semibold"
        >{{'calendarComponent.appointment.newAppointment.fields.notes' |
        translate}}*</label
      >
      <textarea
        id="notes-modal"
        type="text"
        pInputTextarea
        [rows]="5"
        [placeholder]="'general.form.enterSubject'| translate: {subject: ('calendarComponent.appointment.newAppointment.fields.notes' | translate)}"
        [(ngModel)]="appointment.notes"
        (ngModelChange)="onNotesChanged($event)"
        style="resize: none"></textarea>
    </div>
  </div>
  <p-footer>
    <div class="flex gap-2 justify-content-end">
      <div>
        <button
          pButton
          pRipple
          [label]="'general.form.cancel' | translate"
          class="flex-auto w-full"
          type="button"
          (click)="onCancel()"></button>
      </div>
      <div>
        <button
          pButton
          pRipple
          [label]="'general.form.delete' | translate"
          class="flex-auto w-full"
          type="button"
          [disabled]="saveDisabled"
          (click)="onSave()"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
