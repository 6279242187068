<form [@fadeAnimation] [formGroup]="form" *ngIf="!isLoading">
  <div class="card first-card">
    <h6 class="text-primary">
      {{'ticketComponent.editForm.sections.header.title' | translate |
      uppercase}}
    </h6>
    <app-ticket-header
      #ticketHeader
      [ticket]="editTicket"
      (returnToOverview)="closeEditForm()"></app-ticket-header>
  </div>
  <div #cardBody id="cardBody" class="card-body" (scroll)="onScroll()">
    <div class="leftPanel w-9">
      <form [formGroup]="form">
        <div class="card mb-4" #ticketDetailSection>
          <h6 class="text-primary">
            {{'ticketComponent.editForm.sections.details.title' | translate |
            uppercase}}
          </h6>
          <app-ticket-details
            formGroupName="detailData"
            [(ticket)]="editTicket"></app-ticket-details>
        </div>

        <div class="card mb-4" #ticketInformationSection>
          <h6 class="text-primary">
            {{'ticketComponent.editForm.sections.ticketInformation.title' |
            translate | uppercase}}
          </h6>
          <app-ticket-information
            #ticketInformation
            [(ticket)]="editTicket"
            (ticketChange)="generateSubject()"
            [editors]="users"
            [(showDevices)]="showDevices"
            [(showForm)]="showForm"
            [(isReoccurringTicket)]="isReoccurringTicket"
            [disabled]="!$can('update')"
            (reoccurringInterval)="reoccurringInterval($event)"
            formGroupName="ticketInformationData"></app-ticket-information>
        </div>

        <div class="card mb-4" #ticketCustomerSection>
          <h6 class="text-primary">
            {{'ticketComponent.editForm.sections.customer.title' | translate |
            uppercase}}
          </h6>
          <app-ticket-customer
            (customerChanged)="customerChanged($event)"
            [(ticket)]="editTicket"
            [customers]="customers"
            [countries]="countries"
            [getCustomersIsLoading]="getCustomersIsLoading"
            formGroupName="customer"></app-ticket-customer>
          <h6 *ngIf="editTicket.customer" class="text-primary">
            {{'ticketComponent.editForm.sections.contactPerson.title' |
            translate | uppercase}}
          </h6>
          <app-ticket-contact-person
            *ngIf="editTicket.customer"
            [(ticket)]="editTicket"
            [customers]="customers"
            [countries]="countries"
            [customerContactPersons]="customerContactPersons"
            formGroupName="customerContactPerson"></app-ticket-contact-person>
        </div>

        <div #ticketCustomerDevicesSection>
          <div class="card mb-4" *ngIf="showDevices">
            <h6 class="text-primary">
              {{'ticketComponent.editForm.sections.devices.title' | translate |
              uppercase}}
            </h6>
            <app-ticket-devices
              [(ticket)]="editTicket"
              (ticketChange)="generateSubject()"
              formGroupName="deviceData"></app-ticket-devices>
          </div>
        </div>

        <div class="card mb-4" #ticketAttachmentsSection>
          <h6 class="text-primary">
            {{'ticketComponent.editForm.sections.attachments.title' | translate
            | uppercase}}
          </h6>
          <app-ticket-attachments
            [(ticket)]="editTicket"
            formGroupName="attachmentData"></app-ticket-attachments>
        </div>

        <div #ticketAppointmentsSection>
          <div class="card mb-4" *ngIf="showAppointment">
            <app-ticket-appointment
              [ticket]="editTicket"
              formGroupName="appointmentData"></app-ticket-appointment>
          </div>
        </div>

        <div #ticketFormsSection>
          <div class="card mb-4" *ngIf="showForm">
            <h6 class="text-primary">
              {{'ticketComponent.editForm.sections.form.title' | translate |
              uppercase}}
            </h6>
            <app-ticket-form
              [isInAppointment]="false"
              (ticketChange)="formChanged()"
              (ticketUpdated)="ticketUpdated($event)"
              [users]="users"
              [(ticket)]="editTicket"></app-ticket-form>
          </div>
        </div>

        <div style="min-height: 150px" class="divider">&nbsp;</div>
      </form>
    </div>
    <div class="rightPanel card w-2">
      <div class="nav-container">
        <ul>
          <li
            (click)="scrollToSection(ticketDetailSection, 1)"
            [class]="selectedNavLink === 1 ? 'selected' : ''">
            {{'ticketComponent.editForm.sections.details.title' | translate}}
          </li>
          <li
            (click)="scrollToSection(ticketInformationSection, 2)"
            [class]="selectedNavLink === 2 ? 'selected' : ''">
            {{'ticketComponent.editForm.sections.ticketInformation.title' |
            translate}}
          </li>
          <li
            (click)="scrollToSection(ticketCustomerSection, 3)"
            [class]="selectedNavLink === 3 ? 'selected' : ''">
            {{'ticketComponent.editForm.sections.customer.title' | translate}}
          </li>
          <li
            *ngIf="showDevices"
            (click)="scrollToSection(ticketCustomerDevicesSection, 4)"
            [class]="selectedNavLink === 4 ? 'selected' : ''">
            {{'ticketComponent.editForm.sections.devices.title' | translate}}
          </li>
          <li
            (click)="scrollToSection(ticketAttachmentsSection, 5)"
            [class]="selectedNavLink === 5 ? 'selected' : ''">
            {{'ticketComponent.editForm.sections.attachments.title' |
            translate}}
          </li>

          <li
            *ngIf="showAppointment"
            (click)="scrollToSection(ticketAppointmentsSection, 6)"
            [class]="selectedNavLink === 6 ? 'selected' : ''">
            {{'ticketComponent.editForm.sections.appointment.title' |
            translate}}
          </li>
          <li
            *ngIf="showForm"
            (click)="scrollToSection(ticketFormsSection, 7)"
            [class]="selectedNavLink === 7 ? 'selected' : ''">
            {{'ticketComponent.editForm.sections.form.title' | translate}}
          </li>
        </ul>
      </div>
      <ng-container class="buttons">
        <div class="mt-8 first-button-container" *ngIf="$can('update')">
          <button
            pButton
            pRipple
            [label]="'general.form.save' | translate"
            class="flex-auto w-full"
            [loading]="isLoading"
            type="button"
            [disabled]="submitDisabled"
            (click)="save()"></button>
        </div>
        <div class="mt-2">
          <button
            pButton
            pRipple
            [label]="'general.form.cancel' | translate"
            class="flex-auto w-full"
            [loading]="isLoading"
            type="button"
            (click)="cancel()"></button>
        </div>
      </ng-container>
    </div>
  </div>
</form>
<app-ticket-reoccurring-modal
  *ngIf="editTicket"
  [ticket]="editTicket"
  (executionDateChanged)="executionDateChanged($event)"
  [display]="showReoccurringModal"></app-ticket-reoccurring-modal>
