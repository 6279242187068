import { User } from 'src/app/models/User';

/**
 * Checks if the current user has permission to unlock files.
 *
 * @returns {boolean} True if the user has permission to unlock files, false otherwise.
 */
export function hasPermissionToUnlockFiles(currentUser: User): boolean {
  return currentUser.role === 'Admin' || currentUser.role === 'TechnicalAdmin';
}
