import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Appointment } from 'src/app/models/Appointment';

@Component({
  selector: 'app-delete-appointment-modal',
  templateUrl: './delete-appointment-modal.component.html',
  styleUrl: './delete-appointment-modal.component.scss'
})
export class DeleteAppointmentModalComponent implements OnInit {
  @Input() display: boolean = false;

  @Input({ required: true }) appointment!: Appointment;

  @Output() modalClosed = new EventEmitter<boolean>();

  @Output() notesChanged = new EventEmitter<string>();

  saveDisabled = true;

  header = '';

  constructor(private readonly translate: TranslateService) {}

  ngOnInit(): void {
    const title = this.translate.instant(
      'calendarComponent.deleteApointmentModal.title'
    );

    this.header = `${title}`;
  }

  onCancel(): void {
    this.display = false;
    this.saveDisabled = true;
    this.modalClosed.emit(false);
  }

  onSave(): void {
    this.display = false;
    this.saveDisabled = true;
    this.modalClosed.emit(true);
  }

  onNotesChanged(notes: string): void {
    this.saveDisabled = false;
    this.notesChanged.emit(notes);
  }
}
