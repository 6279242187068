import { ReoccurringInterval } from 'src/app/models/ReoccurringInterval';
import {
  TicketCategory,
  TicketStatus,
  ClientType
} from 'src/app/models/Ticket';

// All ticket status values
const allStatusChips = [
  TicketStatus.Planning,
  TicketStatus.Open,
  TicketStatus.InProgress,
  TicketStatus.Paused,
  TicketStatus.Cancelled,
  TicketStatus.Done,
  TicketStatus.Goodwill,
  TicketStatus.Complaint,
  TicketStatus.Complained,
  TicketStatus.Settle,
  TicketStatus.Settled,
  TicketStatus.Completed
];

// status values for current tickets
export const statusChipsCurrent = allStatusChips.filter(
  (status) =>
    status !== TicketStatus.Cancelled && status !== TicketStatus.Completed
);

// status values for archived tickets
export const statusChipsArchive = allStatusChips.filter(
  (status) =>
    status === TicketStatus.Cancelled || status === TicketStatus.Completed
);

// export all status values
export const statusChips = allStatusChips;

export const categoryChips = [
  TicketCategory.Commissioning,
  TicketCategory.Emergency,
  TicketCategory.Maintenance,
  TicketCategory.SGC,
  TicketCategory.Malfunction,
  TicketCategory.Task
];

export const reoccurring: string[] = [
  ReoccurringInterval.HalfYearly,
  ReoccurringInterval.Annually,
  ReoccurringInterval.Biennially,
  ReoccurringInterval.None
];

export const clientTypes = [
  ClientType.Craftsman,
  ClientType.EndCustomer,
  ClientType.NothaftHeiztechnik,
  ClientType.Unknown
];
